import { appStore } from "../store/peer";

const maxScore = 10;

const getScoreColor = (score: number) => {
  if (score < 3) return "red";
  if (score < 7) return "yellow";
  return "green";
};

export const PeerScore = ({ score }: { score: number }) => {
  if (score === 0) return <></>;
  return (
    <div
      style={{
        maxWidth: "300px",
        display: "flex",
        gap: "3px",
        alignItems: "center",
      }}
    >
      score:{score.toFixed(2)}{" "}
      {Array.from({ length: Number(score.toFixed(0)) }).map((_, i) => (
        <div
          key={i}
          style={{
            width: "10px",
            height: "7px",
            borderRadius: "3px",
            backgroundColor: getScoreColor(score),
          }}
        ></div>
      ))}
    </div>
  );
};
