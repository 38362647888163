import * as SimplePeer from "simple-peer";

import { SignalData } from "simple-peer";
type PeerInstance = SimplePeer.Instance;

export type { PeerInstance, SignalData };
export declare type NetworkQualityStatsSample = {
  avgJitter: number;
  rtt: number;
  packetsLoss: number;
};
export declare type NetworkScore = number;

export declare type NetworkScores = {
  outbound?: NetworkScore;
  inbound?: NetworkScore;
  statsSamples: {
    outboundStatsSample?: NetworkQualityStatsSample;
    inboundStatsSample?: NetworkQualityStatsSample;
  };
};
export enum ClientToServerCommand {
  CREATE_ROOM = "CREATE_ROOM",
  JOIN_ROOM = "JOIN_ROOM",
  PREPARE_PEER = "PREPARE_PEER",
}
export interface CreateRoomRequest {
  type: "meeting" | "conference";
  myId: string;
  myName: string;
  requestId: string;
}

export type JoinRoomRequest = {
  roomId: string;
  myName: string;
  requestId: string;
  participantId: string;
};
export enum PupeteerCommandNames {
  GIVE_ME_NEW_OFFER = "GIVE_ME_NEW_OFFER",
  NETWORK_UPDATE = "NETWORK_UPDATE",
  USE_SERVER_FOR_COMMUNICATION = "USE_SERVER_FOR_COMMUNICATION",
  CONTINUE_PEER_TO_PEER_COMMUNICATION = "CONTINUE_PEER_TO_PEER_COMMUNICATION",
  FORWARD_YOUR_STREAM_TO_SERVER = "FORWARD_YOUR_STREAM_TO_SERVER",
  STOP_FORWARDING_STREAM_TO_SERVER = "STOP_FORWARDING_STREAM_TO_SERVER",
  CHANGE_YOUR_ROLE = "CHANGE_YOUR_ROLE",
  GIVE_ME_YOUR_PUBLIC_KEY = "GIVE_ME_YOUR_PUBLIC_KEY",
  DESTROY_YOURSELF = "DESTROY_YOURSELF",
}

export enum ParticipantsCommandNames {
  CONNECTION_PROBLEM_REPORT = "CONNECTION_PROBLEM_REPORT",
  SCORE_CONNECTIO_QUALITY_WITH_ANOTHER_PARTICIPANT = "SCORE_CONNECTIO_QUALITY_WITH_ANOTHER_PARTICIPANT",
  RENEGOTIATE = "RENEGOTIATE",
  OFFER_TO_CONNECT = "OFFER_TO_CONNECT",
  ACCEPT_OFFER_TO_CONNECT = "ACCEPT_OFFER_TO_CONNECT",
  PROVIDE_MY_PUBLIC_KEY = "PROVIDE_MY_PUBLIC_KEY",
  CONNECT_DISCONNECT_EVENT_BETWEEN_NODES = "CONNECT_DISCONNECT_EVENT_BETWEEN_NODES",
  GIVE_ME_PARTICIPANT_PUBLIC_KEY = "GIVE_ME_PARTICIPANT_PUBLIC_KEY",
  PROVIDE_PARTICIPANT_PUBLIC_KEY = "PROVIDE_PARTICIPANT_PUBLIC_KEY",
  CONNECT_ME_TO_SOMEONE = "CONNECT_ME_TO_SOMEONE",
  CONNECT_ME_TO_SOMEONE_RESPONSE = "CONNECT_ME_TO_SOMEONE_RESPONSE",
  FORWARD_YOUR_STREAM_TO_SERVER_RESPONSE = "FORWARD_YOUR_STREAM_TO_SERVER_RESPONSE",
}

export enum ConnectionRole {
  DISTRIBUTOR = "DISTRIBUTOR",
  RECEIVER = "RECEIVER",
  SENDER_RCEIVER = "SENDER_RCEIVER",
}

export type GiveMeParticipantPublicKey = {
  name: ParticipantsCommandNames.GIVE_ME_PARTICIPANT_PUBLIC_KEY;
  participantId: string;
  messageId: string;
};
export type GiveMeYourPublicKey = {
  name: PupeteerCommandNames.GIVE_ME_YOUR_PUBLIC_KEY;
};
export type ProvideMyPublicKey = {
  name: ParticipantsCommandNames.PROVIDE_MY_PUBLIC_KEY;
  publicKey: string;
};
export type ProvideParticipantPublicKey = {
  name: ParticipantsCommandNames.PROVIDE_PARTICIPANT_PUBLIC_KEY;
  publicKey: string;
  participantId: string;
  messageId: string;
};

export type GiveMeNewOfferCommand = {
  name: PupeteerCommandNames.GIVE_ME_NEW_OFFER;
  yourId: string;
  targetId: string;
  requestId: string;
  disconnectFromThisIdAfterConnection?: string;
};
export type UseServerForCommunicationCommand = {
  name: PupeteerCommandNames.USE_SERVER_FOR_COMMUNICATION;
  yourId: string;
  requestId: string;
};
export type ContinuePeerToPeerCommunicationCommand = {
  name: PupeteerCommandNames.CONTINUE_PEER_TO_PEER_COMMUNICATION;
  yourId: string;
  requestId: string;
};
export type ScoreConnectionQualityWithAnotherParticipant = {
  name: ParticipantsCommandNames.SCORE_CONNECTIO_QUALITY_WITH_ANOTHER_PARTICIPANT;
  comingFrom: string;
  scoreForConnectionWith: string;
  scores: NetworkScores;
};
export type ForwardYourStreamToServerCommand = {
  name: PupeteerCommandNames.FORWARD_YOUR_STREAM_TO_SERVER;
  yourId: string;
  requestId: string;
  receivers: string[];
};
export type ForwardYourStreamToServerCommandResponse = {
  name: ParticipantsCommandNames.FORWARD_YOUR_STREAM_TO_SERVER_RESPONSE;
  requestId: string;
  receivers: string[];
  streamIds: string[];
};
export type StopForwardingStreamToServerCommand = {
  name: PupeteerCommandNames.STOP_FORWARDING_STREAM_TO_SERVER;
  yourId: string;
  requestId: string;
};
export type RenegotiateCommand = {
  name: ParticipantsCommandNames.RENEGOTIATE;
  originId: string;
  targetId: string;
  requestId: string;
  signalData: SignalData;
};

export type DestroySelfCommand = {
  name: PupeteerCommandNames.DESTROY_YOURSELF;
  yourId: string;
};

export type OfferToConnect = {
  name: ParticipantsCommandNames.OFFER_TO_CONNECT;
  offerOrigin: string;
  offer: SignalData;
  requestId: string;
};
export type AcceptOfferToConnect = {
  name: ParticipantsCommandNames.ACCEPT_OFFER_TO_CONNECT;
  sender: string;
  answerTo: string;
  answer: SignalData;
  requestId: string;
};

export type ConnectMeToSomeone = {
  name: ParticipantsCommandNames.CONNECT_ME_TO_SOMEONE;
  originId: string;
  requestId: string;
};
export type ConnectMeToSomeoneResponse = {
  name: ParticipantsCommandNames.CONNECT_ME_TO_SOMEONE_RESPONSE;
  connectedTo: string;
  requestId: string;
};
export type ConnectionEventBetweenParticipants = {
  name: ParticipantsCommandNames.CONNECT_DISCONNECT_EVENT_BETWEEN_NODES;
  comingFrom: string;
  connectedTo?: string;
  requestId: string;
  disconnectedFrom?: string;
};
export type NetworkConfigUpdate = {
  name: PupeteerCommandNames.NETWORK_UPDATE;
  config: NetworkConfig;
  participantId: string;
};
export type NetworkConfig = {
  [id: string]: {
    connections: string[];
    name: string;
    networkScore: number;
  };
};
export type ChangeYourRoleCommand = {
  name: PupeteerCommandNames.CHANGE_YOUR_ROLE;
};
export type ParticipantConnections = {
  [id: string]: { id: string; duty?: ConnectionDuty };
};
export type PendingParticipantConnections = {
  [id: string]: { id: string; duty?: ConnectionDuty; timeStamp: number };
};
export type ParticipantRole =
  | "creator"
  | "moderator"
  | "roomMember"
  | "listener";
export type ConnectionTimeoutError = {
  reason: `timeout`;
  participantId: string;
  otherId: string;
};
export type ConnectionFailedError = {
  reason: `failed`;
  participantId: string;
  otherId: string;
  data: ConnectionEventBetweenParticipants;
};
export type ConnectionError = ConnectionTimeoutError | ConnectionFailedError;

export type ConnectionDuty = "channel" | "send" | "receive";

export type CommandsFromParticipant =
  | ProvideMyPublicKey
  | ForwardYourStreamToServerCommandResponse
  | GiveMeParticipantPublicKey
  | ScoreConnectionQualityWithAnotherParticipant
  | ConnectMeToSomeone;

export type Command =
  | DestroySelfCommand
  | GiveMeNewOfferCommand
  | GiveMeYourPublicKey
  | ProvideParticipantPublicKey
  | UseServerForCommunicationCommand
  | ContinuePeerToPeerCommunicationCommand
  | ForwardYourStreamToServerCommand
  | StopForwardingStreamToServerCommand
  | RenegotiateCommand
  | OfferToConnect
  | AcceptOfferToConnect
  | ConnectionEventBetweenParticipants
  | NetworkConfigUpdate
  | ConnectMeToSomeoneResponse;
