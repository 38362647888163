import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CreateRoom } from "./pages/createRoom";
import { JoinRoom } from "./pages/joinRoom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import TRTC from "trtc-js-sdk";

// User interaction hack!
const audio = new Audio();
const onClick = () => {
  audio.play();
  window.removeEventListener("click", onClick);
};
window.addEventListener("click", onClick);

function App() {
  useEffect(() => {
    TRTC.checkSystemRequirements().then((result) => {
      if (
        !result?.result ||
        !result?.detail.isBrowserSupported ||
        !result?.detail.isWebRTCSupported
      ) {
        alert("Your browser does not support TRTC");
      }
    });
  }, []);
  return (
    <>
      <ToastContainer
        transition={Slide}
        position="top-right"
        closeOnClick={false}
        closeButton={false}
        autoClose={2000}
        rtl={false}
        newestOnTop
        draggable
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route path={"/"} element={<CreateRoom />} />
          <Route path={"/:id"} element={<JoinRoom />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
