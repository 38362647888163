import { createWithEqualityFn } from "zustand/traditional";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

import { createSocket } from "../utils/createSocket";
import { nanoid } from "nanoid";
import { Peer } from "../peer";
import { NetworkConfig, NetworkScores } from "../../server/node-soup/src";
import { createSelectors } from "./createSelectors";
import { IssueDetectorResult } from "../utils/issueDetector/types";

export type ParticipantTracks = {
  audio?: {
    enabled: boolean;
    track: MediaStreamTrack;
  };
  video?: {
    enabled: boolean;
    track: MediaStreamTrack;
  };
  screen?: {
    enabled: boolean;
    track: MediaStreamTrack;
  };
};
export interface AppState {
  peer: Peer;
  peerName: string;
  MyMessages: {
    from: string;
    message: string;
  }[];
  isJoining: boolean;
  isPeerConnected: boolean;
  localPeers: {
    [id: string]: Peer;
  };
  connectionScores: { [id: string]: NetworkScores };
  connectionIssues: { [id: string]: IssueDetectorResult };
  network: NetworkConfig;
  networkWithoutScores: {
    [id: string]: {
      name: string;
      connections: string[];
    };
  };
  screenTracks: {
    [id: string]: {
      enabled: boolean;
      track: MediaStreamTrack;
    };
  };

  webcamTracks: {
    [id: string]: {
      enabled: boolean;
      track: MediaStreamTrack;
    };
  };
  audioTracks: {
    [id: string]: {
      enabled: boolean;
      track: MediaStreamTrack;
    };
  };
}

const initialState: AppState = {
  peer: new Peer({
    id: nanoid(),
    socket: createSocket(),
  }),
  peerName:
    import.meta.env.VITE_ENVIRONMENT === "develop" ? nanoid().slice(0, 4) : "",

  // import.meta.env.VITE_ENVIRONMENT === "develop" ? nanoid() : "",
  MyMessages: [],
  connectionScores: {},
  connectionIssues: {},
  isJoining: false,
  isPeerConnected: false,
  localPeers: {},
  network: {},
  networkWithoutScores: {},
  screenTracks: {},
  webcamTracks: {},
  audioTracks: {},
};

export const appStore = createSelectors(
  createWithEqualityFn<AppState>()(
    devtools(
      immer(() => initialState),
      {
        enabled: true,
        name: "appStore",
      }
    )
  )
);

export const storeActions = {
  newIncomingMessage: (message: { from: string; message: string }) => {
    const network = appStore.getState().network;
    const senderName = network[message.from]?.name || message.from;
    message.from = senderName;
    appStore.setState((state) => {
      state.MyMessages.push(message);
    });
  },
  setTrackStatus: ({
    id,
    type,
    enabled,
  }: {
    id: string;
    type: "audio" | "video" | "screen";
    enabled: boolean;
  }) => {
    appStore.setState((state) => {
      switch (type) {
        case "audio":
          state.audioTracks[id] = {
            enabled,
            track: state.audioTracks[id]?.track,
          };
          break;
        case "video":
          state.webcamTracks[id] = {
            enabled,
            track: state.webcamTracks[id]?.track,
          };
          break;
        case "screen":
          state.screenTracks[id] = {
            enabled,
            track: state.screenTracks[id]?.track,
          };
          break;
      }
    });
  },
  sendDirectMessage: async ({
    id,
    message,
  }: {
    id: string;
    message: string;
  }) => {
    const myPeer = appStore.getState().peer;
    const res = await myPeer.sendTextMessageToParticipant({
      targetID: id,
      message,
    });
    console.log("direct message delivered", res);
  },
  setIsPeerConnected: (isPeerConnected: boolean) => {
    appStore.setState({ isPeerConnected });
  },
  setIsJoining: (isJoining: boolean) => {
    appStore.setState({ isJoining });
  },
  setMyPeerName: (name: string) => {
    appStore.setState((state) => {
      state.peer.name = name;
      state.peerName = name;
    });
  },
  addLocalPeer: (p?: Peer) => {
    const id = p?.id || nanoid();
    const peer =
      p ||
      new Peer({
        id,
        socket: createSocket(),
      });
    appStore.setState((state) => {
      // @ts-ignore ignored
      state.localPeers[id] = peer;
    });
    return peer;
  },

  setPeerLocaltrack: ({
    type,
    track,
  }: {
    type: "audio" | "video" | "screen";
    track: MediaStreamTrack | undefined;
  }) => {
    const myId = appStore.getState().peer.id;
    appStore.setState((state) => {
      switch (type) {
        case "audio":
          state.audioTracks[myId] = {
            enabled: !!track,
            // @ts-ignore
            track,
          };
          break;
        case "video":
          state.webcamTracks[myId] = {
            enabled: !!track,
            // @ts-ignore
            track,
          };
          break;
        case "screen":
          state.screenTracks[myId] = {
            enabled: !!track,
            // @ts-ignore
            track,
          };
          break;
      }
    });
  },
};

const peer = appStore.getState().peer;

const onPeerConnect = () => {
  appStore.setState({ isPeerConnected: true });
  document.title = peer.id;
};

const onNetworkUpdate = (data: NetworkConfig) => {
  appStore.setState({ network: data });
  const networkWithoutScores = Object.fromEntries(
    Object.entries(data).map(([key, value]) => {
      const { networkScore, ...rest } = value;
      return [key, rest];
    })
  );
  appStore.setState({ networkWithoutScores });
};

const onIncomingAudioTrack = ({
  from,
  track,
}: {
  from: string;
  track: MediaStreamTrack;
}) => {
  appStore.setState((state) => {
    state.audioTracks[from] = {
      enabled: !!track,
      track,
    };
  });
};

const onIncomingVideoTrack = ({
  from,
  track,
}: {
  from: string;
  track: MediaStreamTrack;
}) => {
  appStore.setState((state) => {
    state.webcamTracks[from] = {
      enabled: !!track,
      track,
    };
  });
};

const onIncomingScreenTrack = ({
  from,
  track,
}: {
  from: string;
  track: MediaStreamTrack;
}) => {
  appStore.setState((state) => {
    state.screenTracks[from] = {
      enabled: !!track,
      track,
    };
  });
};

const handleLocalTrackChange = ({
  track,
  type,
}: {
  type: "video" | "audio" | "screen";
  track: MediaStreamTrack | undefined;
}) => {
  storeActions.setPeerLocaltrack({ type, track });
};

const onIncomingTextMessage = (data: { from: string; message: string }) => {
  storeActions.newIncomingMessage(data);
};

const onConnectionScore = (data: {
  connectionId: string;
  scores: NetworkScores;
}) => {
  const myPeerConnections = appStore.getState().peer.connections;
  if (!myPeerConnections[data.connectionId]) return;
  appStore.setState((state) => {
    state.connectionScores[data.connectionId] = data.scores;
  });
};

peer.once("connect", onPeerConnect);
peer.on("connectionScore", onConnectionScore);
peer.on("connectionIssue", (data) => {
  appStore.setState((state) => {
    state.connectionIssues[data.connectionId] = data.issues;
  });
});
peer.on("mediaShareEventFromParticipant", (data) => {
  storeActions.setTrackStatus({
    id: data.participantId,
    type: data.type,
    enabled: data.state === "started",
  });
});
peer.on("incomingTextMessage", onIncomingTextMessage);
peer.on("networkUpdate", onNetworkUpdate);
peer.on("incomingAudioTrack", onIncomingAudioTrack);
peer.on("incomingVideoTrack", onIncomingVideoTrack);
peer.on("incomingScreenTrack", onIncomingScreenTrack);

peer.on("webcamShare", (track) =>
  handleLocalTrackChange({
    type: "video",
    track,
  })
);

peer.on("screenShare", (track) =>
  handleLocalTrackChange({ type: "screen", track })
);

peer.on("audioShare", (track) =>
  handleLocalTrackChange({ type: "audio", track })
);

peer.on("audioShareStopped", () => {
  handleLocalTrackChange({ type: "audio", track: undefined });
});

peer.on("webcamShareStopped", () => {
  handleLocalTrackChange({ type: "video", track: undefined });
});

peer.on("screenShareStopped", () => {
  handleLocalTrackChange({ type: "screen", track: undefined });
});
