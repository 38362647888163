import TRTC, {
  AudioProfileString,
  LocalStream,
  ScreenProfileString,
  VideoProfileString,
} from "trtc-js-sdk";
import AudioMixerPlugin from "rtc-audio-mixer";
import { watermarkTheWebcamStream } from "./pixiRenderer";
import { EventEmitter } from "pixi.js";

export class MediaController {
  localScreenStream: LocalStream | undefined = undefined;
  localWebcamStream: LocalStream | undefined = undefined;
  localAudioStream: LocalStream | undefined = undefined;
  filteredWebcamStream = new MediaStream();
  filteredSharedScreenStream = new MediaStream();
  destroyFilteredWebcamStream: () => void = () => {};
  destroyFilteredSharedScreenStream: () => void = () => {};
  onScreenShareStopped: () => void;
  mics: MediaDeviceInfo[] = [];
  speakers: MediaDeviceInfo[] = [];
  cameras: MediaDeviceInfo[] = [];
  selectedMic: MediaDeviceInfo | undefined = undefined;
  selectedSpeaker: MediaDeviceInfo | undefined = undefined;
  selectedCamera: MediaDeviceInfo | undefined = undefined;

  initialized = false;
  peerId: string;

  constructor({
    peerId,
    onScreenShareStopped,
  }: {
    peerId: string;
    onScreenShareStopped: () => void;
  }) {
    this.peerId = peerId;
    this.onScreenShareStopped = onScreenShareStopped;
    TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.NONE);
    this.initialize();
    return this;
  }

  initialize = async () => {
    if (this.initialized) return;
    const mics = TRTC.getMicrophones();
    const speakers = TRTC.getSpeakers();
    const cameras = TRTC.getCameras();
    const localScreenStream = TRTC.createStream({
      screenAudio: false,
      screen: true,
    });
    localScreenStream.on("screen-sharing-stopped", () => {
      console.log("screen-sharing-stopped");
      this.stopScreenSharing();
    });

    localScreenStream.on("error", () => {
      this.stopScreenSharing();
    });
    const localWebcamStream = TRTC.createStream({
      audio: false,
      video: true,
    });

    const localAudioStream = TRTC.createStream({
      audio: true,
      video: false,
    });
    this.localAudioStream = localAudioStream;
    this.localScreenStream = localScreenStream;
    this.localWebcamStream = localWebcamStream;
    this.mics = await mics;
    this.speakers = await speakers;
    this.cameras = await cameras;
    this.setActiveCamera(this.cameras?.[0]?.deviceId);
    this.setActiveMic(this.mics?.[0]?.deviceId);
    this.setActiveSpeaker(this.speakers?.[0]?.deviceId);

    const AudioMixerSupported = AudioMixerPlugin.isSupported();
    if (!AudioMixerSupported) {
      console.error("Your browser is not compatible with AudioMixerPlugin");
    }

    this.initialized = true;
  };
  get microphonesList() {
    return this.mics;
  }
  get speakersList() {
    return this.speakers;
  }
  get camerasList() {
    return this.cameras;
  }

  switchMic = async (deviceId: string) => {
    await this.localAudioStream?.switchDevice("audio", deviceId);
    return this.shareAudioMic();
  };
  switchCamera = async (deviceId: string) => {
    await this.localWebcamStream?.switchDevice("video", deviceId);
    return this.shareWebcam();
  };

  shareAudioMic = async (config?: { quality: AudioProfileString }) => {
    // ckeck if the mic is already initialized
    if (this.localAudioStream?.getAudioTrack()) {
      this.localAudioStream.unmuteAudio();
      return this.localAudioStream?.getAudioTrack();
    }
    this.localAudioStream?.setAudioProfile(config?.quality || "standard");
    await this.localAudioStream?.initialize();
    const track = this.localAudioStream?.getAudioTrack();

    return track;
  };
  stopMic = () => {
    this.localAudioStream?.muteAudio();
  };

  shareWebcam = async (config?: { fps?: number }) => {
    let rawTrack;
    if (this.localWebcamStream) {
      if (this.localWebcamStream.getVideoTrack()) {
        this.localWebcamStream.unmuteVideo();
        rawTrack = this.localWebcamStream.getVideoTrack();
      } else {
        this.localWebcamStream?.setVideoProfile("120p");
        await this.localWebcamStream?.initialize();
        rawTrack = this.localWebcamStream?.getVideoTrack();
      }
    }

    if (!rawTrack) {
      console.error("unable to get the webcam track");
      return;
    }

    // const watermarked = await watermarkTheWebcamStream({
    //   watermark: this.peerId,
    //   // @ts-ignore
    //   stream: this.localWebcamStream.mediaStream_,
    //   fps: config?.fps,
    // });

    // const { track, destroy } = watermarked;
    // this.localWebcamStream?.replaceTrack(track);
    // this.destroyFilteredWebcamStream = destroy;
    // return track;
    return rawTrack;
  };

  stopWebcamSharing = () => {
    this.localWebcamStream?.muteVideo();
    // this.destroyFilteredWebcamStream();
  };

  shareScreen = async (config?: { fps?: number }) => {
    // check if the screen is already initialized
    let rawTrack;
    if (this.localScreenStream) {
      if (this.localScreenStream.getVideoTrack()) {
        this.localScreenStream.unmuteVideo();
        rawTrack = this.localScreenStream.getVideoTrack();
      } else {
        try {
          this.localScreenStream?.setVideoProfile("720p");
          await this.localScreenStream?.initialize();
          rawTrack = this.localScreenStream?.getVideoTrack();
        } catch (error) {
          this.stopScreenSharing();
          this.onScreenShareStopped();
        }
      }
    }

    // let t: MediaStreamTrack | null | undefined;
    // if (rawTrack) {
    //   const { track, destroy } = await watermarkTheWebcamStream({
    //     watermark: this.peerId,
    //     // @ts-ignore
    //     stream: this.localScreenStream.mediaStream_,
    //     // width: 1280,
    //     // height: 720,
    //     fps: config?.fps,
    //   });
    //   this.destroyFilteredSharedScreenStream = destroy;
    //   t = track;
    // }
    return rawTrack;
  };

  resumeScreenSharing = async () => {
    this.shareScreen();
  };

  stopScreenSharing = () => {
    this.localScreenStream?.close();
    this.onScreenShareStopped();
  };

  setActiveMic = async (deviceId: string) => {
    await this.localAudioStream?.switchDevice("audio", deviceId);
    this.selectedMic = this.mics.find((m) => m.deviceId === deviceId);
  };
  setActiveSpeaker = async (deviceId: string) => {
    this.selectedSpeaker = this.speakers.find((m) => m.deviceId === deviceId);
  };
  setActiveCamera = async (deviceId: string) => {
    await this.localWebcamStream?.switchDevice("video", deviceId);
    this.selectedCamera = this.cameras.find((m) => m.deviceId === deviceId);
  };
  setSharingWebcamQuality = (quality: VideoProfileString) => {
    this.localWebcamStream?.setVideoProfile(quality);
  };
  setSharingScreenQuality = (quality: VideoProfileString) => {
    this.localScreenStream?.setVideoProfile(quality);
  };
  setSharingVideoQuality = (profile: ScreenProfileString) => {
    this.localWebcamStream?.setScreenProfile(profile);
  };
}
