import { Socket } from "socket.io-client";
export const wsAsyncRequest = async <T>({
  requestId,
  socket,
  timout,
}: {
  requestId: string;
  socket: Socket;
  timout?: number;
}) => {
  return new Promise<T>((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject(new Error("timeout"));
    }, timout || 5000);
    const listener = (data: T) => {
      clearTimeout(timeout);
      resolve(data);
    };
    socket.once(requestId, (data: T) => {
      listener(data);
    });
  });
};
