import { Fragment, useMemo } from "react";
import { appStore } from "../../store/peer";
import { Screen } from "./screen";

export const ScreenShares = () => {
  const screenTracks = appStore.use.screenTracks();
  return (
    <div>
      <>
        {Object.keys(screenTracks).map((key) => {
          if (
            !screenTracks[key] ||
            !screenTracks[key].enabled ||
            !screenTracks[key].track
          ) {
            return <Fragment key={key}></Fragment>;
          }

          const track = screenTracks[key].track;
          return <Screen key={key} id={key} />;
        })}
      </>
    </div>
  );
};
