export const iceServers = [
  {
    urls: "stun:stun.l.google.com:19302",
  },
  // {
  //   urls: "stun:stun1.l.google.com:19302",
  // },
  // {
  //   urls: "stun:stun2.l.google.com:19302",
  // },
  // {
  //     'urls': 'stun:stun3.l.google.com:19302'
  // },
  // {
  //   urls: "stun:stun4.l.google.com:19302",
  // },
  ///////////////////////////////////////////////
  {
    urls: "turn:turn-ir.ting.tube",
    username: "ting",
    credential: "KIABUScyIUS888#$",
  },
  {
    urls: "turn:turn-gr-nur.ting.tube",
    username: "ting",
    credential: "ABU*Ycoa8s7c898^%",
  },
  {
    urls: "turn:turn-us-hil.ting.tube",
    username: "ting",
    credential: "O$%#cxiavystcy87",
  },
  // {
  //   urls: "turn:turn-us-ash.ting.tube",
  //   username: "ting",
  //   credential: "Oxcajk87^*&chj",
  // },
  // {
  //   urls: "turn:turn-gr2.ting.tube",
  //   username: "turn-server-gr2",
  //   credential: "OINOInxiushbcssS",
  // },
  // {
  //   urls: "turn:turn-ca.ting.tube",
  //   username: "turn-server-ca",
  //   credential: "OIUACOasiCBSucoiasu878",
  // },
  // {
  //   urls: "turn:turn-sg.ting.tube",
  //   username: "turn-server-sg",
  //   credential: "KIuybckIUASvycv78aSC",
  // },
  // {
  //   urls: "turn:turn-gr.ting.tube",
  //   username: "turn-server-gr",
  //   credential: "AUISBCoa8&VSC*ASBUIc",
  // },
  // {
  //   urls: "turn:turn-ir.ting.tube",
  //   username: "turn-server-ir",
  //   credential: "Sckjas86AVicubao9s8c7",
  // },
];
