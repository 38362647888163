import { useRef, useState } from "react";
import { shallow } from "zustand/shallow";
import { Peer } from "../peer";
import { NetworkDiagram } from "../graph";
import { dataParser } from "../graph/data";
import {
  ClientToServerCommand,
  CreateRoomRequest,
  JoinRoomRequest,
} from "../../server/node-soup/src/index.ts";
import { createSocket } from "../utils/createSocket";
import { nanoid } from "nanoid";
import { wsAsyncRequest } from "../utils/wsAsyncRequest.ts";
import { LittleRoom } from "../components/littleRoom.tsx";
import { appStore, storeActions } from "../store/peer.ts";
import { BottomActions } from "../components/bottomActions.tsx";
import { toast } from "react-toastify";

export const CreateRoom = () => {
  const [roomId, setRoomId] = useState("");
  const name = appStore.use.peerName();
  const peer = appStore.use.peer();
  const network = appStore.use.network();
  const peers = appStore.use.localPeers();
  const numberOfFakePeers = useRef(0);
  const { addLocalPeer, setMyPeerName } = appStore(
    () => ({
      addLocalPeer: storeActions.addLocalPeer,
      setMyPeerName: storeActions.setMyPeerName,
    }),
    shallow
  );

  const createRoom = async () => {
    const requestId = nanoid();
    const sendingData: CreateRoomRequest = {
      myName: name,
      type: "conference",
      myId: peer.id,
      requestId,
    };
    peer.socket.emit(ClientToServerCommand.CREATE_ROOM, sendingData);
    const { roomId } = await wsAsyncRequest<{
      participantId: string;
      roomId: string;
    }>({
      socket: peer.socket,
      requestId,
    });
    peer.myRole = "creator";
    addLocalPeer(peer);
    setRoomId(roomId);
  };
  const addPeer = async (targetRoomId?: string) => {
    numberOfFakePeers.current++;
    const requestId = nanoid();
    const myId = nanoid();
    const socket = createSocket();
    const newPeer = new Peer({
      id: myId,
      socket,
    });
    const joinRoomRequest: JoinRoomRequest = {
      roomId: targetRoomId || roomId,
      requestId: requestId,
      participantId: myId,
      myName: `fake peer ${numberOfFakePeers.current}`,
    };
    socket.emit(ClientToServerCommand.JOIN_ROOM, joinRoomRequest);
    await wsAsyncRequest({
      socket,
      requestId: requestId,
      timout: 30_000,
    });
    addLocalPeer(newPeer);
  };

  return (
    <>
      <div className="p-3 pb-28 flex">
        <div style={{ minWidth: "260px" }}>
          {roomId ? (
            <>
              <button
                className="btn"
                onClick={async () => {
                  const firstPeerId = Object.keys(peers)[1];
                  const firstPeer = peers[firstPeerId];
                  const lastPeerId =
                    Object.keys(peers)[Object.keys(peers).length - 1];
                  const res = await firstPeer.shadowConnect(lastPeerId);
                  console.log({ res });
                }}
              >
                shadow connect test
              </button>
              <br />
              <br />
              <button
                className="btn"
                onClick={() => {
                  const currentLocation = window.location.href;
                  const newLocation = currentLocation + roomId;
                  navigator.clipboard.writeText(newLocation);
                  toast.success("room link copied to clipboard");
                }}
              >
                copy room link
              </button>
              <br />
            </>
          ) : (
            <div className="flex gap-2">
              <input
                className="input"
                value={name}
                placeholder="enter your name"
                onChange={(e) => setMyPeerName(e.target.value)}
              />
              <button className="btn" disabled={!name} onClick={createRoom}>
                Create Room
              </button>
            </div>
          )}
          <br />
          <br />

          {roomId ? (
            <div>
              <p className="mb-4">
                Room ID: {roomId}, number of participants:{" "}
                {Object.keys(network).length}
              </p>
              <div className="flex gap-3">
                <button className="btn" onClick={() => addPeer()}>
                  add Peer
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    for (let i = 0; i < 10; i++) {
                      addPeer();
                    }
                  }}
                >
                  add shitload of Peers
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* {Object.keys(peers).length > 1 ? (
          <>
            <p>actions</p>
            <button onClick={requestMesh}>mesh us!</button>
          </>
        ) : (
          <></>
        )} */}
        <div>
          <LittleRoom />
        </div>
      </div>
      <BottomActions />
    </>
  );
};
