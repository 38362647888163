import useIsSpeaking from "../hooks/useIsSpeaking";

export const TalkDetector = ({ track }: { track?: MediaStreamTrack }) => {
  const audioLevel = useIsSpeaking(track);
  return (
    <div
      className="transition-all duration-500 ease-in-out"
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        border: audioLevel ? "5px solid blue" : "5px solid transparent",
        pointerEvents: "none",
      }}
    />
  );
};
