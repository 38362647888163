import { useEffect, useMemo, useState } from "react";
import { NetworkDiagram } from "../graph";
import { dataParser } from "../graph/data";
import { appStore } from "../store/peer";

export const Graph = ({ showing }: { showing: boolean }) => {
  const [visible, setVisible] = useState(showing);
  const myPeer = appStore.use.peer();
  const tracks = {
    audio: appStore.use.audioTracks(),
    screen: appStore.use.screenTracks(),
    webcam: appStore.use.webcamTracks(),
  };
  const networkWithoutScores = appStore.use.networkWithoutScores();

  return (
    <>
      <div className=" ">
        <button onClick={() => setVisible(!visible)} className="btnSmall">
          {visible ? "hide graph" : "showGraph"}
        </button>
        {useMemo(() => {
          return (
            <>
              {Object.keys(networkWithoutScores).length > 0 ? (
                <div
                  className={`absolute rounded-sm overflow-hidden bg-cyan-100 left-0 -mt-14 ${
                    visible ? "-translate-y-full" : "translate-y-0 opacity-0"
                  } pointer-events-none transition-all duration-300`}
                >
                  <NetworkDiagram
                    width={900}
                    height={600}
                    data={dataParser(networkWithoutScores, tracks)}
                    myId={myPeer.id}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          );
        }, [
          JSON.stringify(networkWithoutScores),
          JSON.stringify(tracks),
          visible,
        ])}
      </div>
    </>
  );
};
