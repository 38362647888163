import { FC } from "react";
import { appStore } from "../store/peer";

export const MyMessages: FC = () => {
  const myMessages = appStore.use.MyMessages();
  return (
    <>
      <div>
        {myMessages.map((message, index) => (
          <p
            key={index}
            style={{
              fontSize: "12px",
            }}
          >
            from:<span>{message.from}</span> : {message.message}
          </p>
        ))}
      </div>
    </>
  );
};
