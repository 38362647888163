import { LittlePeer } from "./littlePeer";
import { appStore } from "../store/peer";
import { Fragment } from "react/jsx-runtime";
import { ScreenShares } from "./screenShares";

export const LittleRoom = () => {
  const network = appStore.use.network();
  const participantIds = Object.keys(network);
  const myPeer = appStore.use.peer();
  if (!myPeer || participantIds.length < 1) return <></>;
  return (
    <div>
      <h1>Little Room</h1>
      <ScreenShares />
      <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
        {participantIds.map((id) => {
          if (myPeer?.id !== id && network[id].connections.length < 1)
            return <Fragment key={id}></Fragment>;
          const name = network[id].name;
          return <LittlePeer key={id} id={id} name={name} />;
        })}
      </div>
    </div>
  );
};
