import { useMemo } from "react";
import { appStore } from "../../store/peer";

export const Screen = ({ id }: { id: string }) => {
  const track = appStore((store) => store.screenTracks[id]);
  if (!track) return <></>;
  return (
    <div className="bg-stone-600 w-fit p-3 m-3 rounded-md">
      <p>{appStore.getState().network[id].name}</p>
      {useMemo(
        () => (
          <video
            style={{
              minWidth: "640px",
              maxWidth: "1280px",
              borderRadius: "5px",
            }}
            autoPlay={true}
            ref={(v) => {
              if (v) {
                v.srcObject = new MediaStream([track.track]);
              }
            }}
          />
        ),
        [track.enabled, track.track.id]
      )}
    </div>
  );
};
