import { Link, Node } from "./data";
import screenSvg from "./screen.svg";
import audioSvg from "./audio.svg";
import webcamSvg from "./webcam.svg";
export const RADIUS = 5;

export const drawNetwork = (
  context: CanvasRenderingContext2D,
  width: number,
  height: number,
  nodes: Node[],
  links: Link[],
  myId: string
) => {
  context.clearRect(0, 0, width, height);
  // Draw the links first
  links.forEach((link) => {
    context.beginPath();
    // @ts-ignore
    context.moveTo(link.source.x, link.source.y);
    //  @ts-ignore
    context.lineTo(link.target.x, link.target.y);
    context.stroke();
  });

  // Draw the nodes
  nodes.forEach((node, index) => {
    if (!node.x || !node.y) {
      return;
    }
    if (node.tracks?.screen?.enabled) {
      // draw a green dot on top of the node circle
      context.beginPath();
      context.moveTo(node.x + RADIUS, node.y);
      context.arc(node.x, node.y - 8, RADIUS, 0, 2 * Math.PI);
      context.fillStyle = "green";
      context.fill();
    }
    if (node.tracks?.audio?.enabled) {
      // draw a yellow dot on left of the node circle
      context.beginPath();
      context.moveTo(node.x - RADIUS, node.y);
      context.arc(node.x - 10, node.y, RADIUS, 0, 2 * Math.PI);
      context.fillStyle = "yellow";
      context.fill();
    }
    if (node.tracks?.video?.enabled) {
      // draw  a red circle on right of the node circle
      context.beginPath();
      context.moveTo(node.x + RADIUS, node.y);
      context.arc(node.x + RADIUS + 5, node.y, RADIUS, 0, 2 * Math.PI);
      context.fillStyle = "blue";
      context.fill();
    }

    context.beginPath();
    context.moveTo(node.x + RADIUS, node.y);
    context.arc(node.x, node.y, RADIUS, 0, 2 * Math.PI);
    context.fillStyle = node.id === myId ? "#ff0000" : "#ff00ff";
    context.fill();
    context.fillStyle = "#000";

    // add a screen svg on top of the node circle
    if (node.tracks.screen?.enabled) {
    }

    context.font = "12px Arial";
    context.fillText(
      `${
        import.meta.env.VITE_ENVIRONMENT === "develop" ? "" : node.name + "-"
      }${node.id.slice(0, 2)}`,
      node.x + 5,
      node.y - 5
    );
  });
};
