import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./styles.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <div className="hidden md:block">
      <App />
    </div>
    <div className="sm:block md:hidden">
      <h1 className="text-2xl text-center">
        This app is not yet supported on mobile
      </h1>
    </div>
  </>
);
