import { FC, useMemo } from "react";

import { appStore } from "../store/peer";
import { TalkDetector } from "./talkDetector";
import { MyMessages } from "./myMessages";
import { DirectMessageInput } from "./messageInput";
import { PeerScore } from "./peerScore";

export const LittlePeer: FC<{
  id: string;
  name: string;
}> = ({ id, name }) => {
  const myPeer = appStore.use.peer();
  const isMe = myPeer?.id === id;
  const webcamTrack = appStore.use.webcamTracks()[id];
  const audioTrack = appStore.use.audioTracks()[id];
  const thisPeerScore = appStore.use.network()[id].networkScore;

  return (
    <div
      className="p-4 flex flex-col  min-h-96 min-w-80 relative gap-2 rounded-md 
      bg-gray-600 shadow-md
    "
    >
      <TalkDetector track={audioTrack?.track} />
      <span
        className="
      text-white text-lg font-bold
      "
      >
        name: {name}
      </span>
      <br />
      <PeerScore score={thisPeerScore} />
      <br />
      <span>{id}</span>
      {useMemo(() => {
        if (!webcamTrack?.track || !webcamTrack.enabled || !webcamTrack.track)
          return <></>;
        return (
          <div style={{ flex: "1" }}>
            {webcamTrack ? (
              <video
                style={{
                  minWidth: "320px",
                  maxWidth: "320px",
                  borderRadius: "5px",
                }}
                autoPlay={true}
                ref={(v) => {
                  if (v) {
                    v.srcObject = new MediaStream([webcamTrack.track]);
                  }
                }}
              ></video>
            ) : (
              <></>
            )}
          </div>
        );
      }, [webcamTrack])}
      {/* {useMemo(
        () => (
          <div>
            {screenTrack ? (
              <video
                style={{
                  minWidth: "320px",
                  maxWidth: "320px",
                  borderRadius: "5px",
                }}
                autoPlay={true}
                ref={(v) => {
                  if (v) {
                    v.srcObject = new MediaStream([screenTrack]);
                  }
                }}
              ></video>
            ) : (
              <></>
            )}
          </div>
        ),
        [screenTrack]
      )} */}
      {useMemo(() => {
        if (!audioTrack?.track || !audioTrack.enabled || !audioTrack.track)
          return <></>;
        return (
          <div>
            {audioTrack && id !== myPeer?.id ? (
              <audio
                autoPlay={true}
                ref={(a) => {
                  if (a) {
                    a.srcObject = new MediaStream([audioTrack.track]);
                  }
                }}
              ></audio>
            ) : (
              <></>
            )}
          </div>
        );
      }, [audioTrack])}
      {!isMe && audioTrack ? (
        <div>
          <button
            className="dangerSmall"
            onClick={() => {
              if (myPeer) {
                myPeer.commandParticipant({
                  command: "mute",
                  id,
                });
              }
            }}
          >
            mute
          </button>
        </div>
      ) : (
        <></>
      )}
      {isMe ? <MyMessages /> : <DirectMessageInput id={id} />}
    </div>
  );
};
