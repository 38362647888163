import { FC, useState } from "react";
import { shallow } from "zustand/shallow";
import { appStore, storeActions } from "../store/peer";

export const DirectMessageInput: FC<{ id: string }> = ({ id }) => {
  const [message, setMessage] = useState("");
  const { sendDirectMessage } = appStore(
    () => ({
      sendDirectMessage: storeActions.sendDirectMessage,
    }),
    shallow
  );
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleSendClick = () => {
    sendDirectMessage({ id, message });
    setMessage("");
  };

  return (
    <div className="flex justify-center items-center gap-3">
      <input
        className="input"
        value={message}
        onChange={onInputChange}
        type="text"
      />
      <button
        className="btnSmall"
        disabled={!message}
        onClick={handleSendClick}
      >
        Send
      </button>
    </div>
  );
};
