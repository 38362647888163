import {
  AcceptOfferToConnect,
  Command,
  PupeteerCommandNames,
  ConnectionEventBetweenParticipants,
  GiveMeNewOfferCommand,
  GiveMeParticipantPublicKey,
  ParticipantsCommandNames,
  OfferToConnect,
  ProvideMyPublicKey,
  ProvideParticipantPublicKey,
  RenegotiateCommand,
  ConnectMeToSomeoneResponse,
  PeerInstance,
  SignalData,
  ForwardYourStreamToServerCommandResponse,
  NetworkConfig,
  ConnectMeToSomeone,
  ScoreConnectionQualityWithAnotherParticipant,
  NetworkScores,
} from "../server/node-soup/src/index.ts";

import WebRTCIssueDetector, {
  IssueDetectorResult,
} from "./utils/issueDetector";

import EventEmitter from "events";
import { nanoid } from "nanoid";

import { JSEncrypt } from "jsencrypt";
import { MediaController } from "./utils/mediaController";
import { newPeer } from "./utils/newPeer/newpeer.ts";
import { Socket } from "socket.io-client";
import {
  P2PMessage,
  NetworkGraph,
  Connection,
  GlobalMessagePayload,
  WhosStreamIsThisResponse,
  ShadowConnectionMessage,
  ShadowConnectionMessageResponse,
  P2PCommand,
  DirectP2PMessage,
  DirectP2PMessageResponse,
  PeerConnection,
  MediaShareEvent,
  GlobalChatEvent,
} from "./types.ts";
interface PeerEventMap {
  connect: { peer: Peer; newPeerId: string };
  disconnect: { peer: Peer };
  message: P2PMessage;
  networkUpdate: NetworkConfig;
  webcamShare: MediaStreamTrack;
  webcamShareStopped: undefined;
  audioShare: MediaStreamTrack;
  audioShareStopped: undefined;
  screenShare: MediaStreamTrack;
  screenShareStopped: undefined;
  mediaShareEventFromParticipant: MediaShareEvent;
  globalChatEvent: GlobalChatEvent;
  incomingAudioTrack: { track: MediaStreamTrack; from: string };
  incomingVideoTrack: { track: MediaStreamTrack; from: string };
  incomingScreenTrack: { track: MediaStreamTrack; from: string };
  directMessage: DirectP2PMessage;
  incomingTextMessage: { from: string; message: string };
  messageDelivered: { messageId: string };
  connectionScore: { connectionId: string; scores: NetworkScores };
  connectionIssue: { connectionId: string; issues: IssueDetectorResult };
}
export class Peer extends EventEmitter {
  socket: Socket;
  id: string;
  name: string = "participant";
  myRole?: "creator" | "participant";
  #eventEmitter = new EventEmitter();
  network: NetworkGraph = {};
  networkFromPupeteer: NetworkGraph = {};
  myWebcamAndMicStream = new MediaStream();
  myScreenStream = new MediaStream();
  #connectionReportsEnabled = true;
  latestNetworkConfigFromPupeteer: NetworkConfig = {};
  issueDetector: WebRTCIssueDetector;
  isSharing: {
    audio: boolean;
    video: boolean;
    screen: boolean;
  } = {
    audio: false,
    video: false,
    screen: false,
  };
  #mediaController: MediaController;
  sentMessages: { [id: string]: string[] } = {};
  #crypto = {
    publicKey: "",
    privateKey: "",
    _encrypt: new JSEncrypt({
      default_key_size: "2048",
    }),
    encryptMessage: (data: string) => {
      const encrypted = this.#crypto._encrypt.encrypt(data);
      return encrypted;
    },
    decryptMessage: (data: string) => {
      const decrypted = this.#crypto._encrypt.decrypt(data);
      return decrypted;
    },
  };

  connections: Connection = {};
  shadowConnections: Connection = {};
  constructor({
    id,
    socket,
  }: {
    socket: typeof Peer.prototype.socket;
    id: string;
  }) {
    super();
    this.socket = socket;
    this.id = id;
    this.#mediaController = new MediaController({
      peerId: this.id,
      onScreenShareStopped: () => {
        console.log("screen sharing stopped");
        this.emit("screenShareStopped", undefined);
        this.sendGlobalMessage({
          payload: {
            name: "mediaShareEvent",
            participantId: this.id,
            state: "stopped",
            type: "screen",
          },
        });
        this.isSharing.video = false;
        this.myScreenStream
          .getVideoTracks()
          .forEach((t) => this.myScreenStream.removeTrack(t));
      },
    });
    this.#crypto.publicKey = this.#crypto._encrypt.getPublicKey();
    this.#crypto.privateKey = this.#crypto._encrypt.getPrivateKey();
    this.issueDetector = new WebRTCIssueDetector({
      autoAddPeerConnections: false,
      getStatsInterval: 5_000,
      onNetworkScoresUpdated: (scores) => {
        this.#emitConnectionScore({
          scoreReport: {
            comingFrom: this.id,
            name: ParticipantsCommandNames.SCORE_CONNECTIO_QUALITY_WITH_ANOTHER_PARTICIPANT,
            scoreForConnectionWith: scores.id!,
            scores,
          },
        });
      },
    });
    this.socket.onAny(async (_name: string, incommingMessage) => {
      await this.#handleIncomingMessageFromPupeteer(incommingMessage);
    });
  }
  on<E extends keyof PeerEventMap>(
    event: E,
    listener: (data: PeerEventMap[E]) => void
  ): this {
    return super.on(event, listener);
  }
  once<E extends keyof PeerEventMap>(
    event: E,
    listener: (data: PeerEventMap[E]) => void
  ): this {
    return super.once(event, listener);
  }
  // Override the 'emit' method to use your event names and data types
  emit<E extends keyof PeerEventMap>(event: E, data: PeerEventMap[E]): boolean {
    return super.emit(event, data);
  }

  async randomConnectedParticipant(avoid?: string[]) {
    const currentGraph = await this.currentNetworkConfig();
    let ids = Object.keys(currentGraph)
      .filter((id) => !avoid?.includes(id))
      .filter((id) => id !== this.id);
    if (ids.length === 0) {
      Object.keys(currentGraph).filter((id) => id !== this.id);
    }
    const randomIndex = Math.floor(Math.random() * ids.length);
    return ids[randomIndex];
  }
  async connectMeToSomeone() {
    return new Promise<ConnectMeToSomeoneResponse>((resolve, reject) => {
      const requestId = nanoid();
      const req: ConnectMeToSomeone = {
        name: ParticipantsCommandNames.CONNECT_ME_TO_SOMEONE,
        originId: this.id,
        requestId: nanoid(),
      };
      this.socket.emit(ParticipantsCommandNames.CONNECT_ME_TO_SOMEONE, req);
      this.#eventEmitter.once(requestId, (data: ConnectMeToSomeoneResponse) => {
        resolve(data);
      });
    });
  }

  propagateGlobalChatEvent({
    replyToMessageId,
    type,
    payload,
  }: {
    type: GlobalChatEvent["type"];
    payload: GlobalChatEvent["payload"];
    replyToMessageId?: string;
  }) {
    const dataToSend: GlobalChatEvent = {
      name: "globalChatEvent",
      messageId: nanoid(),
      senderId: this.id,
      sentAt: Date.now(),
      type,
      replyToMessageId,
      payload,
    };
    this.sendGlobalMessage({
      payload: dataToSend,
    });
    this.emit("globalChatEvent", dataToSend);
    return dataToSend;
  }
  // #debouncedEmitConnectionScore = debounce(this.#emitConnectionScore, 1000, {
  //   maxWait: 1000,
  // });

  #emitConnectionScore({
    scoreReport,
  }: {
    scoreReport: ScoreConnectionQualityWithAnotherParticipant;
  }) {
    const { scores } = scoreReport;
    if (!scores || !scores.inbound || !scores.outbound) return;
    this.emit("connectionScore", {
      connectionId: scoreReport.scoreForConnectionWith,
      scores: scoreReport.scores,
    });
    this.socket.emit(
      ParticipantsCommandNames.SCORE_CONNECTIO_QUALITY_WITH_ANOTHER_PARTICIPANT,
      scoreReport
    );
  }
  #emitConnectionIssue({
    connectionId,
    issues,
  }: {
    connectionId: string;
    issues: IssueDetectorResult;
  }) {
    this.emit("connectionIssue", { connectionId, issues });
  }

  #startConnectionReports(id?: string) {
    const startFn = (connection: PeerConnection) => {
      if (!this.#connectionReportsEnabled) return;
      // @ts-ignore
      const pc = connection.peer._pc;
      this.issueDetector.handleNewPeerConnection(pc, connection.id);
      console.log(
        `%c attached issue detector to ${connection.id}`,
        "background: #222; color: #bada55"
      );
    };
    if (id) {
      startFn(this.connections[id]);
    } else {
      const allConnections = Object.keys(this.connections);
      allConnections.forEach((connectionId) => {
        const connection = this.connections[connectionId];
        startFn(connection);
      });
    }
  }

  async sendGlobalMessage({
    payload,
    avoid = [],
  }: {
    payload: GlobalMessagePayload;
    avoid?: string[];
  }) {
    const connections = this.connections;
    Object.keys(connections)
      .filter((id) => !avoid.includes(id))
      .forEach((id) => {
        const connection = this.connections[id];
        if (!connection.peer.connected) {
          return;
        }
        this.sendMessagetoConnectedParticipant({
          message: {
            senderId: this.id,
            messageId: nanoid(),
            payload,
          },
          targetID: id,
        });
      });
  }
  async #askWhosStreamIsThis({
    streamId,
    askFrom,
  }: {
    streamId: string;
    askFrom?: string;
  }) {
    return new Promise<WhosStreamIsThisResponse>((resolve, reject) => {
      const avoid: string[] = [];
      if (askFrom) {
        const connectionIdsOtherThanAskFrom = Object.keys(
          this.connections
        ).filter((id) => id !== askFrom);
        avoid.push(...connectionIdsOtherThanAskFrom);
      }
      const requestId = nanoid();
      this.sendGlobalMessage({
        payload: {
          name: "WhosStreamIsThis",
          streamId,
          requestId,
          asker: this.id,
        },
        avoid,
      });
      this.#eventEmitter.once(requestId, (data: WhosStreamIsThisResponse) => {
        resolve(data);
      });
    });
  }

  async currentNetworkConfig({
    visitedBefore = {},
  }: {
    visitedBefore?: NetworkGraph;
  } = {}) {
    const myConnections = {
      [this.id]: {
        key: this.#crypto.publicKey,
        connections: Object.keys(this.connections),
        isSharing: this.isSharing,
      },
    };
    const myUnderstanding = { ...visitedBefore, ...myConnections };
    const promises = myConnections[this.id].connections
      .filter((id) => !Object.keys(visitedBefore).includes(id))
      .map((connectionId) => {
        const requestId = nanoid();
        const messageToSendToMyChildren: P2PMessage = {
          payload: {
            name: "figureOutConnections",
            visited: myUnderstanding,
          },
          senderId: this.id,
          messageId: requestId,
        };
        return this.sendMessagetoConnectedParticipant({
          message: messageToSendToMyChildren,
          targetID: connectionId,
        });
      });

    // @ts-ignore
    const results: P2PMessage[] = await Promise.allSettled(promises);
    const finalUnderstanding: NetworkGraph = {};
    results.forEach((r) => {
      if (r instanceof Error) {
        return;
      }
      const res = r as unknown as PromiseFulfilledResult<P2PMessage>;
      const result = res.value;
      if (result?.payload?.name === "figureOutConnectionsResponse") {
        const { visited } = result.payload;
        Object.keys(visited).forEach((id) => {
          const myUnderstandingForThisId = visited[id].connections;
          if (!finalUnderstanding[id]) {
            finalUnderstanding[id] = {
              key: visited[id].key,
              connections: Array.from(new Set(myUnderstandingForThisId)),
              isSharing: visited[id].isSharing,
            };
          } else {
            finalUnderstanding[id] = {
              key: visited[id].key,
              isSharing: visited[id].isSharing,
              connections: Array.from(
                new Set([
                  ...finalUnderstanding[id].connections,
                  ...myUnderstandingForThisId,
                ])
              ),
            };
          }
        });
      }
    });
    return finalUnderstanding;
  }

  #shortestRoute({
    graph,
    start,
    end,
  }: {
    graph: NetworkGraph;
    start: string;
    end: string;
  }): string[] | undefined {
    const myConnections = this.connections;
    if (myConnections[end]) {
      return [start, end];
    }

    // Check if start and end nodes are valid
    if (!graph[start] || !graph[end]) {
      return undefined;
    }

    // Initialize a queue for BFS and a set to keep track of visited nodes
    const queue: [string, string[]][] = [[start, [start]]];
    const visited: Set<string> = new Set([start]);

    // Perform BFS
    while (queue.length > 0) {
      const [node, path] = queue.shift()!;
      if (node === end) {
        return path;
      }
      for (const neighbor of graph[node].connections) {
        if (!visited.has(neighbor)) {
          visited.add(neighbor);
          queue.push([neighbor, path.concat(neighbor)]);
        }
      }
    }
    // If no path is found
    return undefined;
  }

  get listOfMics() {
    return this.#mediaController.microphonesList;
  }
  get selectedMic() {
    return this.#mediaController.selectedMic;
  }
  async setSelectedMic(deviceId: string) {
    this.isSharing.audio = false;
    this.emit("audioShareStopped", undefined);
    const newTrack = await this.#mediaController.switchMic(deviceId);
    if (newTrack) {
      this.shareAudio(newTrack);
    }
  }

  get listOfCameras() {
    return this.#mediaController.camerasList;
  }
  get selectedCamera() {
    return this.#mediaController.selectedCamera;
  }
  async setSelectedCamera(deviceId: string) {
    this.isSharing.video = false;
    this.emit("webcamShareStopped", undefined);
    const newTrack = await this.#mediaController.switchCamera(deviceId);
    if (newTrack) {
      this.shareVideo("webcam", newTrack);
    }
  }
  get selectedSpeaker() {
    return this.#mediaController.selectedSpeaker;
  }
  get listOfSpeakers() {
    return this.#mediaController.speakersList;
  }

  setSelectedSpeaker(deviceId: string) {
    this.#mediaController.localAudioStream?.switchDevice("audio", deviceId);
  }
  mixAudioTracks(tracks: MediaStreamTrack[]) {
    const audioContext = new AudioContext();
    const masterGain = audioContext.createGain();
    const sourcesToMix = tracks.map((t) =>
      audioContext.createMediaStreamSource(new MediaStream([t]))
    );
    const gainNodes = sourcesToMix.map(() => audioContext.createGain());
    sourcesToMix.forEach((source, i) => {
      source.connect(gainNodes[i]);
      gainNodes[i].connect(masterGain);
    });
    const destination = audioContext.createMediaStreamDestination();
    masterGain.connect(destination);
    return destination.stream.getAudioTracks()[0];
  }

  async shareAudio(newTrack?: MediaStreamTrack) {
    const network = await this.currentNetworkConfig();
    // if a lot of people have open mic
    const openMics = Object.keys(network).filter(
      (id) => network[id].isSharing.audio
    );
    if (openMics.length > 5) {
      alert("too many open mics");
      return;
    }

    const mediaController = this.#mediaController;
    const t = newTrack || (await mediaController.shareAudioMic());
    if (!t) {
      console.log("no audio track found");
      return;
    }
    const audioTrack = t; //applyNoisReduction(t);
    // remove audio track from my stream if exists
    // this.myWebcamAndMicStream
    //   .getAudioTracks()
    //   .forEach((t) => this.myWebcamAndMicStream.removeTrack(t));
    // this.myWebcamAndMicStream.addTrack(audioTrack);
    if (newTrack) {
      this.myWebcamAndMicStream.getAudioTracks().forEach((t) => {
        this.myWebcamAndMicStream.removeTrack(t);
      });
      this.myWebcamAndMicStream.addTrack(newTrack);
    } else {
      if (this.myWebcamAndMicStream.getAudioTracks().length > 0) {
        this.myWebcamAndMicStream.getAudioTracks().forEach((t) => {
          t.enabled = false;
          t.enabled = true;
          // this.myWebcamAndMicStream.removeTrack(t);
        });
      } else {
        audioTrack.enabled = true;
        this.myWebcamAndMicStream.addTrack(audioTrack);
      }
    }
    // add audio track to all connections
    Object.keys(this.connections).forEach((connectionId) => {
      this.addStreamToConnection({
        connectionId,
        stream: this.myWebcamAndMicStream,
      });
    });
    this.emit("audioShare", audioTrack);
    this.sendGlobalMessage({
      payload: {
        name: "mediaShareEvent",
        participantId: this.id,
        state: "started",
        type: "audio",
      },
    });
  }
  stopSharingAudio(from?: string) {
    const mediaController = this.#mediaController;
    const audio = mediaController.localAudioStream;
    const track = audio?.getAudioTrack();
    console.log("stopping audio share", from ? ` from ${from}` : "");
    this.isSharing.audio = false;
    if (!track) {
      console.log("no audio track found");
      return;
    }
    this.#mediaController.stopMic();
    this.myWebcamAndMicStream.getAudioTracks().forEach(
      (t) =>
        //
        (t.enabled = false)
      // this.myWebcamAndMicStream.removeTrack(t)
    );
    this.emit("audioShareStopped", undefined);
    this.sendGlobalMessage({
      payload: {
        name: "mediaShareEvent",
        participantId: this.id,
        state: "stopped",
        type: "audio",
      },
    });
  }

  async shareVideo(type: "webcam" | "screen", newTrack?: MediaStreamTrack) {
    const network = await this.currentNetworkConfig();
    if (type === "screen") {
      // if a lot of people have open screen
      const openScreens = Object.keys(network).filter(
        (id) => network[id].isSharing.screen
      );
      if (openScreens.length > 1) {
        alert("too many open screens");
        return;
      }
    } else {
      // if a lot of people have open webcam
      const openWebcams = Object.keys(network).filter(
        (id) => network[id].isSharing.video
      );
      if (openWebcams.length > 8) {
        alert("too many open webcams");
        return;
      }
    }
    const mediaController = this.#mediaController;
    const videoTrack =
      newTrack || type === "webcam"
        ? await mediaController.shareWebcam({
            fps: 10,
          })
        : await mediaController.shareScreen({
            fps: 5,
          });

    if (!videoTrack) {
      console.log("no video stream found");
      return;
    }
    let myLocalStream: MediaStream;
    if (type === "screen") {
      myLocalStream = this.myScreenStream;
    } else {
      myLocalStream = this.myWebcamAndMicStream;
    }
    if (newTrack) {
      myLocalStream
        .getVideoTracks()
        .forEach((t) => myLocalStream.removeTrack(t));
      myLocalStream.addTrack(videoTrack);
    } else {
      if (myLocalStream.getVideoTracks().length > 0) {
        myLocalStream.getVideoTracks().forEach((t) => {
          t.enabled = false;
          t.enabled = true;
          // myLocalStream.removeTrack(t);
        });
      } else {
        videoTrack.enabled = true;
        myLocalStream.addTrack(videoTrack);
      }
    }

    Object.keys(this.connections).forEach((connectionId) => {
      this.addStreamToConnection({
        connectionId,
        stream: myLocalStream,
      });
    });
    if (type === "screen") {
      this.isSharing.screen = true;
      this.emit("screenShare", videoTrack);
      this.sendGlobalMessage({
        payload: {
          name: "mediaShareEvent",
          participantId: this.id,
          state: "started",
          type: "screen",
        },
      });
    } else {
      this.isSharing.video = true;
      this.emit("webcamShare", videoTrack);
      this.sendGlobalMessage({
        payload: {
          name: "mediaShareEvent",
          participantId: this.id,
          state: "started",
          type: "video",
        },
      });
    }
  }
  stopSharingVideo(type: "webcam" | "screen") {
    const mediaController = this.#mediaController;
    const video =
      type === "webcam"
        ? mediaController.localWebcamStream
        : mediaController.localScreenStream;
    const track = video?.getVideoTrack();
    if (!track) {
      console.log("no video track found");
      if (type === "webcam") {
        this.emit("webcamShareStopped", undefined);
        this.isSharing.video = false;
        this.sendGlobalMessage({
          payload: {
            name: "mediaShareEvent",
            participantId: this.id,
            state: "stopped",
            type: "video",
          },
        });
      } else {
        this.emit("screenShareStopped", undefined);
        this.sendGlobalMessage({
          payload: {
            name: "mediaShareEvent",
            participantId: this.id,
            state: "stopped",
            type: "screen",
          },
        });
        this.isSharing.screen = false;
      }
      return;
    }
    const callToRemove = () => {
      if (type === "webcam") {
        this.myWebcamAndMicStream.getVideoTracks().forEach(
          (t) => (t.enabled = false)
          //  this.myWebcamAndMicStream.removeTrack(t)
        );
        this.#mediaController.stopWebcamSharing();
        this.emit("webcamShareStopped", undefined);
        this.isSharing.video = false;
        this.sendGlobalMessage({
          payload: {
            name: "mediaShareEvent",
            participantId: this.id,
            state: "stopped",
            type: "video",
          },
        });
      } else {
        this.myScreenStream.getVideoTracks().forEach(
          (t) => (t.enabled = false)
          // this.myScreenStream.removeTrack(t)
        );
        this.#mediaController.stopScreenSharing();
        this.emit("screenShareStopped", undefined);
        this.sendGlobalMessage({
          payload: {
            name: "mediaShareEvent",
            participantId: this.id,
            state: "stopped",
            type: "screen",
          },
        });
        this.isSharing.screen = false;
      }
    };
    callToRemove();
  }
  async addStreamToConnection({
    connectionId,
    stream,
  }: {
    connectionId: string;
    stream: MediaStream;
  }) {
    const connection = this.connections[connectionId];
    if (!connection) {
      console.log("connection not found");
      return;
    }
    const streamId = stream.id;
    let exists = false;
    connection.peer.streams.forEach((s) => {
      console.log("loging connection streams: ", s.id, streamId);
      if (s.id === streamId) {
        exists = true;
      }
    });
    if (!exists) {
      try {
        connection.peer.addStream(stream);
      } catch (error) {
        console.log(
          "this stream is already added, iterating tracks and adding them if they don't exist"
        );
        stream.getTracks().forEach((track) => {
          try {
            connection.peer.addTrack(track, stream);
          } catch (error) {
            console.log(`${track.kind} track already added`);
          }
        });
      }
    }
    if (connection.outgoingStreams.find((s) => s.id === streamId)) {
      return;
    } else {
      connection.outgoingStreams.push(stream);
    }
  }
  async encryptMessage({
    decryptor,
    message,
  }: {
    message: string;
    decryptor: string;
  }) {
    const graph = await this.currentNetworkConfig();
    const receiverPublicKey = graph[decryptor]?.key;
    const shortestRoute = this.#shortestRoute({
      graph,
      start: this.id,
      end: decryptor,
    });
    if (!shortestRoute) {
      console.log("no route found!");
      return;
    }
    const nextParticipant = shortestRoute?.[1];
    if (!nextParticipant || !receiverPublicKey) {
      console.log("no next participant or receiver public key found");
      return;
    }
    const encryptor = new JSEncrypt({
      default_key_size: "2048",
    });
    const receiverPublicKeyFromPupeteer =
      await this.getParticipantPublicKeyFromPupeteer(decryptor);
    if (receiverPublicKeyFromPupeteer.publicKey !== receiverPublicKey) {
      console.log("someone is being naughty!");
      return;
    }
    const messageToEncrypt = JSON.stringify(message);
    encryptor.setPublicKey(receiverPublicKey);
    let encryptedData: string[] = [];
    if (messageToEncrypt.length > 117) {
      const chunkSize = 117;
      const chunks = messageToEncrypt.match(
        new RegExp(`.{1,${chunkSize}}`, "g")
      );
      if (chunks) {
        chunks.forEach((chunk) => {
          const encrypted = encryptor.encrypt(chunk);
          if (encrypted) {
            encryptedData.push(encrypted);
          }
        });
      }
    } else {
      const encrypted = encryptor.encrypt(messageToEncrypt);
      if (encrypted) {
        encryptedData.push(encrypted);
      }
    }
    if (!encryptedData.length) {
      console.log("encryption failed");
      return;
    }
    return { encryptedData, shortestRoute, graph };
  }

  destroy() {
    const connections = this.connections;
    Object.keys(connections).forEach((id) => {
      const connection = this.connections[id];
      connection.peer.destroy();

      delete connections[id];
    });
    console.log(`peer ${this.id} destroyed`);
    this.socket.disconnect();
  }
  availableShadowConnections() {
    const rawConnections = Object.keys(this.shadowConnections);
    const res: Connection = {};
    rawConnections.forEach((id) => {
      if (this.shadowConnections[id].type !== "main") {
        const connection = this.shadowConnections[id];
        res[id] = connection;
      }
    });
    return res;
  }
  async sendMessageToShadowConnection(message: ShadowConnectionMessage) {
    if (message.name === "makeShadowConnectionMain") {
      return new Promise<ShadowConnectionMessageResponse>((resolve, reject) => {
        const requestId = message.requestId;
        const targetId = message.connectionId;
        const connection = this.shadowConnections[targetId];
        if (!connection) {
          reject(Error("shadow connection not found"));
          return;
        }
        const { peer } = connection;
        // send the message to the shadow connection,
        //listen from the other side on the requestId, continue from function: handleInconmingDataFromShadowConnection
        peer.send(JSON.stringify(message));
        this.#eventEmitter.once(
          requestId,
          (data: ShadowConnectionMessageResponse) => {
            resolve(data);
          }
        );
      });
    }
  }

  async migrateConnectionFromShadowToMain({
    shadowConnectionId,
    relation,
    initiator,
  }: {
    shadowConnectionId: string;
    relation: "child" | "parent";
    initiator?: boolean;
  }) {
    const shadowConnection =
      this.availableShadowConnections()[shadowConnectionId];
    if (!shadowConnection) {
      console.log("shadow connection not found");
      return;
    }
    if (initiator) {
      console.log(
        `migrating ${shadowConnectionId}\n
         from shadow to main\n
         initiator: ${this.id}\n
         requestingTo: ${shadowConnectionId}
         `
      );
      const requestId = nanoid();
      const response = await this.sendMessageToShadowConnection({
        name: "makeShadowConnectionMain",
        // this.id, because other side will need to know who to search for, to make the connection main
        connectionId: this.id,
        requestId,
      });
      return response;
    } else {
      console.log(
        `migrating ${shadowConnectionId} \n
        from shadow to main\n
        initiator: ${shadowConnectionId}\n
        reporter: ${this.id}
        `
      );
      this.completeTheMigrationFromShadowToMain({
        relation,
        shadowConnectionId,
      });
      return true;
    }
  }
  completeTheMigrationFromShadowToMain({
    relation,
    shadowConnectionId,
  }: {
    shadowConnectionId: string;
    relation: "child" | "parent";
  }) {
    const shadowConnection = this.shadowConnections[shadowConnectionId];
    if (!shadowConnection) {
      console.log("shadow connection not found");
      return;
    }
    console.log(`
    completing the migration from shadow to main\n
    shadowConnectionId: ${shadowConnectionId}\n
    reporter: ${this.id}\n
    relation: ${relation}
    `);
    shadowConnection.type = "main";
    shadowConnection.relation = relation;
    this.connections[shadowConnectionId] = shadowConnection;

    shadowConnection.peer.removeAllListeners();
    delete this.shadowConnections[shadowConnectionId];
    this.#addListenersToConnection({
      otherId: shadowConnectionId,
      peer: shadowConnection.peer,
      requestId: nanoid(),
      connectionRelation: relation,
      connectionType: "main",
    });
  }

  async commandParticipant({
    command,
    id,
  }: {
    id: string;
    command: P2PCommand;
  }) {
    this.directMessageToParticipant({
      targetID: id,
      message: {
        payload: {
          name: "directMessage",
          data: [
            JSON.stringify({
              command,
            }),
          ],
        },
      },
    });
  }

  async sendTextMessageToParticipant({
    message,
    targetID,
    messageId = nanoid(),
  }: {
    targetID: string;
    message: string;
    messageId?: string;
  }) {
    return this.directMessageToParticipant({
      targetID,
      message: {
        payload: {
          name: "directMessage",
          data: {
            simpleMessage: message,
          },
        },
      },
    });
  }

  async directMessageToParticipant({
    message,
    targetID,
    messageId = nanoid(),
  }: {
    message: {
      payload: Omit<DirectP2PMessage, "route">;
    };
    targetID: string;
    messageId?: string;
  }) {
    const encryptionResponse = await this.encryptMessage({
      decryptor: targetID,
      message: JSON.stringify(message.payload.data),
    });
    if (!encryptionResponse) {
      console.log("encryption failed^");
      return;
    }
    const { shortestRoute, encryptedData } = encryptionResponse;
    if (!shortestRoute) {
      console.log("no route found!");
      return;
    }
    const nextParticipant = shortestRoute?.[1];
    const MessageToSend: P2PMessage = {
      payload: {
        ...{ ...message.payload, data: encryptedData },
        route: shortestRoute,
      },
      senderId: this.id,
      messageId,
    };
    const response = this.sendMessagetoConnectedParticipant({
      message: MessageToSend,
      targetID: nextParticipant,
    });
    return response;
  }
  async shadowConnect(participantId: string) {
    return new Promise<PeerInstance>((resolve, reject) => {
      const peer = newPeer({
        initiator: true,
      });
      peer.on("signal", async (signal: SignalData) => {
        if (signal.type === "offer") {
          const graph = await this.currentNetworkConfig();
          const route = this.#shortestRoute({
            graph,
            start: this.id,
            end: participantId,
          });
          if (!route) {
            console.log(
              `no route found for shadow connection from ${this.id} to ${participantId}`
            );
            return;
          }
          const directMessage: DirectP2PMessage = {
            name: "directMessage",
            route,
            data: signal,
          };
          const response = (await this.directMessageToParticipant({
            message: { payload: directMessage },
            targetID: participantId,
          })) as P2PMessage;
          if (response?.payload.name === "directMessageResponse") {
            if (response.payload.rejected) {
              reject(
                Error(
                  `connection rejected by ${participantId},reason: ${response.payload.data}`
                )
              );
              peer.destroy();
              return;
            }
            peer.signal(response.payload.data);
          } else {
            reject(
              Error(
                `failed to send offer to ${participantId} from ${this.id} for shadow connection`
              )
            );
          }
        } else {
          console.log("signal type is not offer");
          console.log({ signal });
        }
      });
      peer.on("connect", () => {
        console.log(
          `${this.id} shadow connection established with ${participantId}`
        );
        resolve(peer);
      });
      this.#addListenersToConnection({
        otherId: participantId,
        peer,
        initiator: true,
        requestId: nanoid(),
        connectionRelation: "child",
        connectionType: "shadow",
      });
    });
  }
  async respondToOfferShadowConnection({ message }: { message: P2PMessage }) {
    if (message.payload.name !== "directMessage") {
      console.log("message is not direct message");
      return;
    }
    const route: string[] = message.payload.route;
    if (Object.keys(this.availableShadowConnections()).length > 3) {
      const response = {
        name: "directMessageResponse" as "directMessageResponse",
        route: message.payload.route,
        data: "shadowConnectionLimitReached",
        rejected: true,
      };
      this.sendMessagetoConnectedParticipant({
        message: {
          messageId: message.messageId,
          payload: response,
          senderId: this.id,
        },
        targetID: route[route.indexOf(this.id) - 1],
      });
      return;
    }
    const connection = newPeer({
      initiator: false,
    });
    const nextTarget = route[route.indexOf(this.id) - 1];
    // message is stringified or objectified! in the previous step
    const messageData = message.payload.data as string;
    connection.on("signal", async (signal) => {
      if (signal.type === "answer") {
        const encryptionResponse = await this.encryptMessage({
          decryptor: message.senderId,
          message: JSON.stringify(signal),
        });
        if (!encryptionResponse) {
          console.log("encryption failed while sending answer to offer");
          return;
        }
        const { encryptedData } = encryptionResponse;
        const response: DirectP2PMessageResponse = {
          name: "directMessageResponse",
          route,
          ...(encryptedData.length > 0 ? { data: encryptedData } : {}),
        };
        this.sendMessagetoConnectedParticipant({
          message: {
            messageId: message.messageId,
            payload: response,
            senderId: this.id,
          },
          targetID: nextTarget,
        });
      }
    });
    connection.signal(messageData);
    connection.on("connect", () => {
      console.log(`${this.id} shadow connection established with ${route[0]}`);
      this.shadowConnections[route[0]] = {
        peer: connection,
        id: route[0],
        incomingStreams: [],
        outgoingStreams: [],
        relation: "parent",
        type: "shadow",
      };
    });
    this.#addListenersToConnection({
      otherId: route[0],
      peer: connection,
      requestId: message.messageId,
      connectionRelation: "parent",
      connectionType: "shadow",
    });
  }
  #handleCommandFromOtherParticipant({
    command,
    from,
  }: {
    command: P2PCommand;
    from: string;
  }) {
    console.log("command received", command);
    switch (command) {
      case "mute":
        this.stopSharingAudio(from);
        break;
      case "stopSharingScreen":
        this.stopSharingVideo("screen");
        break;
      case "stopSharingVideo":
        this.stopSharingVideo("webcam");
        break;
      default:
        console.log("unknown command");
    }
  }
  #onDirectMessage({ message }: { message: P2PMessage }) {
    if (message.payload.name === "directMessage") {
      this.emit("directMessage", message.payload);
      console.log(`${this.id} received message:  `, message);
      let messageData;
      try {
        if (typeof message.payload.data === "string") {
          messageData = JSON.parse(message.payload.data);
        } else {
          messageData = message.payload.data;
        }
      } catch (error) {
        messageData = message.payload.data;
      }

      // check if the message is a command
      if (typeof messageData === "object" && messageData.simpleMessage) {
        const route = message.payload.route;
        const response = {
          name: "directMessageResponse" as "directMessageResponse",
          route: message.payload.route,
          data: ["delivered"],
        };
        this.sendMessagetoConnectedParticipant({
          message: {
            messageId: message.messageId,
            payload: response,
            senderId: this.id,
          },
          targetID: route[route.indexOf(this.id) - 1],
        });

        this.emit("incomingTextMessage", {
          from: message.payload.route[0],
          message: messageData.simpleMessage,
        });
        return;
      }
      if (typeof messageData !== "string" && !messageData[1]) {
        let p;
        try {
          p = JSON.parse(messageData[0]);
        } catch (error) {
          p = messageData[0];
        }
        if (typeof p === "object" && p.command) {
          this.#handleCommandFromOtherParticipant({
            command: p.command,
            from: message.payload.route[0],
          });
          return;
        }
      }

      const type = messageData.type;
      let existingShadowConnection =
        this.shadowConnections[message.payload.route[0]];
      if (type === "offer") {
        if (!existingShadowConnection) {
          this.respondToOfferShadowConnection({
            message,
          });
          return;
        }
      }
      if (
        type === "renegotiate" ||
        type === "offer" ||
        type === "answer" ||
        type === "transceiverRequest"
      ) {
        console.log(
          "renegotiate, offer, answer or transceiverRequest",
          { messageData },
          { existingShadowConnection }
        );
        if (existingShadowConnection) {
          existingShadowConnection.peer.signal(messageData);
        }
      } else {
        console.error("unknown message type");
      }
    }
  }
  decryptData(data: string[]) {
    const decryptor = new JSEncrypt({
      default_key_size: "2048",
    });
    decryptor.setPrivateKey(this.#crypto.privateKey);
    const decrypted = data.map((data) => decryptor.decrypt(data)).join("");
    let parsed: string | DirectP2PMessageResponse["data"];
    try {
      parsed = JSON.parse(decrypted);
    } catch (error) {
      parsed = decrypted;
    }
    return parsed;
  }
  async handleIncomingMessageFromAnotherParticipant(bufferData: Buffer) {
    const stringified = bufferData.toString();
    let parsed: P2PMessage | string;
    try {
      parsed = JSON.parse(stringified);
    } catch (error) {
      parsed = stringified;
    }
    if (typeof parsed === "string") {
      console.log({ parsed });
    } else {
      switch (parsed.payload.name) {
        case "InternalChangesReport":
          {
            const { payload, senderId } = parsed;
            console.log("incoming global message", this.id, payload);
            if (
              Object.values(this.connections).length === 1 &&
              senderId === Object.keys(this.connections)[0]
            ) {
              return;
            }
            this.sendGlobalMessage({
              avoid: [senderId],
              payload,
            });
          }
          break;
        case "mediaShareEvent": {
          this.emit("mediaShareEventFromParticipant", parsed.payload);
          const { payload, senderId } = parsed;
          this.sendGlobalMessage({
            avoid: [senderId],
            payload,
          });
          break;
        }
        case "globalChatEvent": {
          const { payload, senderId } = parsed;
          this.emit("globalChatEvent", parsed.payload);
          this.sendGlobalMessage({
            avoid: [senderId],
            payload,
          });
          break;
        }
        case "WhosStreamIsThis":
          {
            const myWebcamAndAudioStreamId = this.myWebcamAndMicStream.id;
            const myScreenStreamId = this.myScreenStream.id;
            const myStreamIds = [myWebcamAndAudioStreamId, myScreenStreamId];
            const { asker, streamId } = parsed.payload;
            if (myStreamIds.includes(streamId)) {
              const response: WhosStreamIsThisResponse = {
                name: "WhosStreamIsThisResponse",
                asker,
                streamType:
                  streamId === myWebcamAndAudioStreamId
                    ? "webcamAndAudio"
                    : "screen",
                responderId: this.id,
                requestId: parsed.payload.requestId,
                streamId,
              };
              const everyoneExceptSenderOfThisMessage = Object.keys(
                this.connections
              ).filter((id) => id !== parsed.senderId);
              this.sendGlobalMessage({
                avoid: everyoneExceptSenderOfThisMessage,
                payload: response,
              });
            } else {
              this.sendGlobalMessage({
                payload: parsed.payload,
                avoid: [parsed.senderId],
              });
            }
          }
          break;
        case "WhosStreamIsThisResponse":
          {
            const { asker, requestId } = parsed.payload;
            if (asker === this.id) {
              this.#eventEmitter.emit(requestId, parsed.payload);
            } else {
              this.sendGlobalMessage({
                payload: parsed.payload,
                avoid: [parsed.senderId],
              });
            }
          }
          break;
        case "directMessage":
          {
            const mainTarget =
              parsed.payload.route[parsed.payload.route.length - 1];
            if (mainTarget === this.id) {
              const receivedMessage = parsed.payload.data as string[];
              const decrypted = this.decryptData(receivedMessage);
              this.#onDirectMessage({
                message: {
                  ...parsed,
                  payload: { ...parsed.payload, data: decrypted },
                },
              });
            } else {
              const nextParticipant =
                parsed.payload.route[parsed.payload.route.indexOf(this.id) + 1];
              this.sendMessagetoConnectedParticipant({
                message: parsed,
                targetID: nextParticipant,
              });
            }
          }
          break;
        case "directMessageResponse":
          {
            const route = parsed.payload.route;
            const mainTarget = route[0];
            if (mainTarget === this.id) {
              let data = parsed.payload.data;
              if (data && data.length > 0) {
                data = this.decryptData(data);
              }
              this.#eventEmitter.emit(parsed.messageId, {
                ...parsed,
                payload: { ...parsed.payload, data },
              });

              if (parsed.payload.data === "delivered") {
                this.emit("messageDelivered", parsed);
              }
            } else {
              const nextParticipant = route[route.indexOf(this.id) - 1];
              this.sendMessagetoConnectedParticipant({
                message: parsed,
                targetID: nextParticipant,
              });
            }
          }
          break;
        case "delivered":
          {
            const { messageId }: P2PMessage = parsed;
            this.#eventEmitter.emit(messageId, parsed);
          }
          break;

        case "figureOutConnections":
          {
            const visited = parsed.payload.visited;
            visited[this.id] = {
              key: this.#crypto.publicKey,
              connections: Object.keys(this.connections),
              isSharing: this.isSharing,
            };
            const avoid = [...Object.keys(visited), parsed.senderId];
            const isThereAnyoneElseToSendMessageTo = Object.keys(
              this.connections
            ).find((id) => !avoid.includes(id));
            if (!isThereAnyoneElseToSendMessageTo) {
              // response back to sender
              const response: P2PMessage = {
                messageId: parsed.messageId,
                senderId: this.id,
                payload: {
                  name: "figureOutConnectionsResponse",
                  visited,
                },
              };
              this.sendMessagetoConnectedParticipant({
                message: response,
                targetID: parsed.senderId,
              });
            } else {
              // more levels to go
              const configFromRemainingNodes: NetworkGraph =
                await this.currentNetworkConfig({
                  visitedBefore: visited,
                });
              const response: P2PMessage = {
                messageId: parsed.messageId,
                senderId: this.id,
                payload: {
                  name: "figureOutConnectionsResponse",
                  visited: configFromRemainingNodes,
                },
              };
              this.sendMessagetoConnectedParticipant({
                message: response,
                targetID: parsed.senderId,
              });
            }
          }
          break;
        case "figureOutConnectionsResponse":
          {
            this.#eventEmitter.emit(parsed.messageId, parsed);
          }
          break;
        default:
          {
            const type = parsed.payload.name;
            if (
              type === "renegotiate" ||
              type === "offer" ||
              type === "answer" ||
              type === "transceiverRequest"
            ) {
              console.log("renegotiate  offer  answer  transceiverRequest", {
                parsed,
              });
              const connection = this.connections[parsed.senderId];
              connection.peer.signal(parsed.payload.signal!);
            }
          }
          break;
      }
      // if it's not special type of message, send delivery
      if (
        parsed.payload.name !== "directMessage" &&
        parsed.payload.name !== "directMessageResponse"
      ) {
        this.emit("message", parsed);
        this.sendMessagetoConnectedParticipant({
          message: {
            messageId: parsed.messageId,
            senderId: this.id,
            payload: {
              name: "delivered",
            },
          },
          targetID: parsed.senderId,
        });
      }
    }
  }
  async sendMessagetoConnectedParticipant({
    message,
    targetID,
  }: {
    message: P2PMessage;
    targetID: string;
  }) {
    return new Promise((resolve, reject) => {
      const { messageId } = message;
      // Event listener to handle incoming messages
      const messageHandler = (data: P2PMessage) => {
        resolve(data);
      };
      this.#eventEmitter.once(messageId, messageHandler);
      // Send the message
      const otherParticipant = this.connections[targetID];
      if (otherParticipant && otherParticipant.peer.connected) {
        otherParticipant.peer.send(JSON.stringify(message));
      } else {
        console.log({ targetID }, { message });
        reject(
          Error(`participant not directly connected,${this.id} =/= ${targetID}`)
        );
      }
    });
  }

  async getParticipantPublicKeyFromPupeteer(participantId: string) {
    const messageToSendToPopeteer: GiveMeParticipantPublicKey = {
      name: ParticipantsCommandNames.GIVE_ME_PARTICIPANT_PUBLIC_KEY,
      participantId,
      messageId: nanoid(),
    };
    return new Promise<ProvideParticipantPublicKey>((resolve, reject) => {
      this.#eventEmitter.once(
        messageToSendToPopeteer.messageId,
        (data: ProvideParticipantPublicKey) => {
          resolve(data);
        }
      );
      this.socket.emit(messageToSendToPopeteer.name, messageToSendToPopeteer);
    });
  }

  async #handleIncomingMessageFromPupeteer(message: Command) {
    switch (message.name) {
      case PupeteerCommandNames.DESTROY_YOURSELF: {
        this.destroy();
        return;
      }
      case PupeteerCommandNames.GIVE_ME_YOUR_PUBLIC_KEY: {
        const messageForPupeteer: ProvideMyPublicKey = {
          name: ParticipantsCommandNames.PROVIDE_MY_PUBLIC_KEY,
          publicKey: this.#crypto.publicKey,
        };
        this.socket.emit(messageForPupeteer.name, messageForPupeteer);
        break;
      }
      case ParticipantsCommandNames.CONNECT_ME_TO_SOMEONE_RESPONSE: {
        this.#eventEmitter.emit(message.requestId, message);
        break;
      }
      case ParticipantsCommandNames.PROVIDE_PARTICIPANT_PUBLIC_KEY: {
        const { publicKey, participantId, messageId } = message;
        this.#eventEmitter.emit(messageId, { participantId, publicKey });
        break;
      }
      case PupeteerCommandNames.GIVE_ME_NEW_OFFER: {
        const data: GiveMeNewOfferCommand = message;
        const { requestId, targetId, disconnectFromThisIdAfterConnection } =
          data;
        const otherId = targetId;
        await this.createNewConnection({
          otherId,
          myRole: "initiator",
          disconnectFromThisIdAfterConnection:
            disconnectFromThisIdAfterConnection,
          requestId,
          localTrackerId: nanoid(),
        });
        break;
      }
      case ParticipantsCommandNames.OFFER_TO_CONNECT: {
        const data: OfferToConnect = message;
        const { offer, offerOrigin: sender, requestId } = data;
        const otherId = sender;
        const incomingOfferSignal = offer;
        // this connection is not initiated by our user. We are the receiver, and we will create a peer in response to sender's request to connect
        const connection = newPeer({
          initiator: false,
        });

        connection.on("signal", async (signal) => {
          if (signal.type === "answer") {
            if (this.connections[otherId]) {
              this.sendMessagetoConnectedParticipant({
                message: {
                  messageId: nanoid(),
                  payload: {
                    name: signal.type,
                    signal,
                  },
                  senderId: this.id,
                },
                targetID: otherId,
              });
              return;
            }
            const msg: AcceptOfferToConnect = {
              name: ParticipantsCommandNames.ACCEPT_OFFER_TO_CONNECT,
              sender: this.id,
              answerTo: otherId,
              requestId,
              answer: signal,
            };
            try {
              if (this.connections[otherId]) {
                const peerToDestroy = this.connections[otherId];
                peerToDestroy.peer.destroy();

                delete this.connections[otherId];
              }
              this.connections[otherId] = {
                peer: connection,
                id: otherId,
                incomingStreams: [],
                outgoingStreams: [],
                relation: "child",
                type: "main",
              };
              this.socket.emit(msg.name, msg);
            } catch (error) {
              console.log(error);
            }
          } else {
            this.sendMessagetoConnectedParticipant({
              message: {
                messageId: nanoid(),
                payload: {
                  name: signal.type,
                  signal: signal,
                },
                senderId: this.id,
              },
              targetID: otherId,
            });
          }
        });

        this.#addListenersToConnection({
          otherId,
          peer: connection,
          requestId,
          localTrackerId: nanoid(),
          connectionType: "main",
          connectionRelation: "child",
        });

        try {
          connection.signal(incomingOfferSignal);
        } catch (error) {
          console.log(error);
        }
        break;
      }
      case ParticipantsCommandNames.ACCEPT_OFFER_TO_CONNECT: {
        try {
          const otherId = message.sender;
          const otherPeer = this.connections[otherId];
          const answer = message.answer;
          otherPeer?.peer.signal(answer);
        } catch (error) {
          console.log(error);
        }
        break;
      }
      case PupeteerCommandNames.NETWORK_UPDATE: {
        if (message.participantId === this.id) {
          this.latestNetworkConfigFromPupeteer = message.config;
          this.emit("networkUpdate", message.config);
        }
        break;
      }
      case PupeteerCommandNames.USE_SERVER_FOR_COMMUNICATION: {
        const { yourId, requestId } = message;
        console.log(
          `use server for communication, participant: ${yourId} requestId: ${requestId}`
        );
        break;
      }
      case PupeteerCommandNames.CONTINUE_PEER_TO_PEER_COMMUNICATION: {
        const { yourId, requestId } = message;
        console.log(
          `continue peer to peer communication, participant: ${yourId} requestId: ${requestId}`
        );
        break;
      }
      case PupeteerCommandNames.FORWARD_YOUR_STREAM_TO_SERVER: {
        const { yourId, requestId, receivers } = message;
        const streamsToForward = Object.values(this.connections)
          .filter((c) => {
            if (!receivers.includes(c.id)) {
              console.log(
                `forward your stream to server, participant: ${yourId} requestId: ${requestId} receiver: ${c.id}`
              );
              return true;
            }
          })
          .map((connection) => [...connection.incomingStreams])
          .flat();
        // add my own streams
        streamsToForward.push(this.myWebcamAndMicStream);
        streamsToForward.push(this.myScreenStream);
        const uniqueStreams = streamsToForward.filter(
          (v, i, a) => a.indexOf(v) === i
        );
        const streamIds = uniqueStreams.map((s) => {
          return s.id;
        });
        const msg: ForwardYourStreamToServerCommandResponse = {
          name: ParticipantsCommandNames.FORWARD_YOUR_STREAM_TO_SERVER_RESPONSE,
          requestId,
          streamIds,
          receivers,
        };
        console.log(msg);
        break;
      }
      case PupeteerCommandNames.STOP_FORWARDING_STREAM_TO_SERVER: {
        const { yourId, requestId } = message;
        console.log(
          `stop forwarding stream to server, participant: ${yourId} requestId:${requestId}`
        );
        break;
      }
      case ParticipantsCommandNames.RENEGOTIATE: {
        const { originId }: RenegotiateCommand = message;
        const connection = this.connections[originId];
        console.log(
          `renegotiate with ${originId}, unhandled!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!`
        );
        break;
      }
    }
  }
  async createNewConnection({
    otherId,
    requestId,
    localTrackerId,
    myRole,
    disconnectFromThisIdAfterConnection,
  }: {
    otherId: string;
    myRole: string;
    requestId: string;
    localTrackerId: string;

    disconnectFromThisIdAfterConnection?: string;
  }) {
    return new Promise<string[]>((resolve, reject) => {
      const connection = newPeer({
        initiator: true,
      });
      this.#addListenersToConnection({
        otherId,
        peer: connection,
        disconnectFromThisIdAfterConnection,
        requestId: requestId,
        localTrackerId,
        initiator: !!myRole,
        connectionType: "main",
        connectionRelation: "parent",
      });

      connection.on("signal", (signal) => {
        if (signal.type === "offer") {
          if (this.connections[otherId]) {
            this.sendMessagetoConnectedParticipant({
              message: {
                messageId: nanoid(),
                payload: {
                  name: signal.type,
                  signal,
                },
                senderId: this.id,
              },
              targetID: otherId,
            });
            return;
          }
          try {
            const messagePayload: OfferToConnect = {
              name: ParticipantsCommandNames.OFFER_TO_CONNECT,
              offer: signal,
              offerOrigin: otherId,
              requestId,
            };

            this.socket.emit(messagePayload.name, messagePayload);
            if (this.connections[otherId]) {
              const peerToDestroy = this.connections[otherId];
              peerToDestroy.peer.destroy();
              delete this.connections[otherId];
            }
            this.connections[otherId] = {
              peer: connection,
              id: otherId,
              incomingStreams: [],
              outgoingStreams: [],
              relation: "parent",
              type: "main",
            };
            // resolve({ peer: connection, offer: signal });
          } catch (error) {
            console.log(error);
          }
        }
      });
      this.#eventEmitter.once(localTrackerId, (data) => {
        resolve(data);
      });
    });
  }
  async handleConnect_Disconnect_BetweenPeers(
    data: ConnectionEventBetweenParticipants
  ) {
    try {
      const { disconnectedFrom, connectedTo } = data;
      const connection =
        this.connections[disconnectedFrom! ? disconnectedFrom! : connectedTo!];
      this.socket.emit(data.name, data);
      if (data.connectedTo) {
        if (connection && this.#connectionReportsEnabled) {
          this.#startConnectionReports(connection.id);
        }
      }
      if (disconnectedFrom) {
        // removew the streams my peer is relaying from dicsonnected peer to other connections
        const incomingStreamsFromConnection = connection.incomingStreams;
        const connectionsOtherThanThis = Object.keys(this.connections)
          .filter((id) => id !== disconnectedFrom)
          .map((id) => this.connections[id]);
        connectionsOtherThanThis.forEach((c) => {
          incomingStreamsFromConnection.forEach((stream) => {
            if (c.peer.streams.find((s) => s.id === stream.id)) {
              c.peer.removeStream(stream);
              c.outgoingStreams = c.outgoingStreams.filter(
                (s) => s.id !== stream.id
              );
            }
          });
        });
        // remove incoming data from this node, remove them from other connected nodes
        const incomingStreams = connection.incomingStreams;
        const allConnectionsExceptDisconnected = Object.keys(this.connections)
          .filter((id) => id !== disconnectedFrom)
          .map((id) => this.connections[id]);
        allConnectionsExceptDisconnected.forEach((c) => {
          incomingStreams.forEach((stream) => {
            c.incomingStreams = c.incomingStreams.filter(
              (s) => s.id !== stream.id
            );
            c.peer.removeStream(stream);
          });
        });

        delete this.connections[disconnectedFrom];
      }
    } catch (error) {
      console.log(error);
    }
  }
  async handleInconmingDataFromShadowConnection(
    message: ShadowConnectionMessage
  ) {
    const { name } = message;
    if (name === "makeShadowConnectionMain") {
      console.log(`received makeShadowConnectionMain\n
       from ${message.connectionId}
       `);
      const { connectionId } = message;
      const res = await this.migrateConnectionFromShadowToMain({
        shadowConnectionId: connectionId,
        relation: "child",
        initiator: false,
      });
      if (res) {
        console.log(
          ` ${connectionId} is now main, sending response to ${connectionId}`
        );
        this.sendMessageToShadowConnection({
          name: "makeShadowConnectionMainResponse",
          success: true,
          requestId: message.requestId,
          // tell the other side that the connection is now main, this will be the id in the other side
          connectionId: this.id,
        });
      }
    } else if (name === "makeShadowConnectionMainResponse") {
      console.log(`received makeShadowConnectionMainResponse\n
       from ${message.connectionId}
       `);
      const { success } = message;
      if (success) {
        this.#eventEmitter.emit(message.requestId, message);
      }
    }
  }

  viewTrack({
    streamOwnerId,
    track,
    type,
  }: {
    track: MediaStreamTrack;
    streamOwnerId: string;
    type: "webcam" | "screen" | "audio";
  }) {
    switch (type) {
      case "audio":
        this.emit("incomingAudioTrack", { from: streamOwnerId, track });
        break;
      case "webcam":
        this.emit("incomingVideoTrack", { from: streamOwnerId, track });
        break;
      case "screen":
        this.emit("incomingScreenTrack", { from: streamOwnerId, track });
        break;
    }
  }

  #addListenersToConnection({
    otherId,
    peer,
    disconnectFromThisIdAfterConnection,
    requestId,
    localTrackerId,
    connectionRelation,
    connectionType,
    initiator,
  }: {
    otherId: string;
    peer: PeerInstance;
    localTrackerId?: string;
    disconnectFromThisIdAfterConnection?: string;
    requestId: string;
    initiator?: boolean;
    connectionType: "main" | "shadow";
    connectionRelation: "parent" | "child";
  }) {
    const onMainConnectionConnected = () => {
      if (initiator) {
        console.log(`you: ${this.id}  <->  ${otherId}`);
      } else {
        console.log(`${otherId}  <-> you:  ${this.id}`);
      }
      localTrackerId &&
        this.#eventEmitter.emit(localTrackerId, [this.id, otherId]);

      const allIncomingStreams = Object.keys(this.connections)
        .filter((id) => id !== otherId)
        .map((id) => this.connections[id].incomingStreams)
        .flat();
      const allOutgoingStreams = Object.keys(this.connections)
        .filter((id) => id !== otherId)
        .map((id) => this.connections[id].outgoingStreams)
        .flat();
      const myStreams = [this.myScreenStream, this.myWebcamAndMicStream];
      const allStreams = [
        ...allIncomingStreams,
        ...allOutgoingStreams,
        ...myStreams,
      ];
      // clreate a unique array based on stream id
      const uniqueStreams = allStreams.filter(
        (stream, index, self) =>
          index === self.findIndex((t) => t.id === stream.id)
      );

      uniqueStreams.forEach((stream) => {
        this.addStreamToConnection({
          stream,
          connectionId: otherId,
        });
      });

      this.handleConnect_Disconnect_BetweenPeers({
        name: ParticipantsCommandNames.CONNECT_DISCONNECT_EVENT_BETWEEN_NODES,
        comingFrom: this.id,
        connectedTo: otherId,
        requestId,
      });

      this.emit("connect", { peer: this, newPeerId: otherId });
    };
    const onMainConnectionStream = async (stream: MediaStream) => {
      const response = await this.#askWhosStreamIsThis({
        streamId: stream.id,
        askFrom: otherId,
      });
      const streamOwnerId = response.responderId;
      const streamType = response.streamType;
      console.log(
        `
        new stream from ${otherId} \n
        streamId: ${stream.id} \n
        owned by: ${streamOwnerId}\n
        coming from: ${otherId}\n
        `
      );

      const connection = this.connections[otherId];
      // check if incoming streams already has the stream
      if (!connection.incomingStreams.find((s) => s.id === stream.id)) {
        connection.incomingStreams.push(stream);
      }

      // all the connections except the one that sent the stream
      const allConnectionsExceptStreamSender = Object.keys(this.connections)
        .filter((id) => id !== otherId)
        .map((id) => this.connections[id]);
      allConnectionsExceptStreamSender.forEach((c) => {
        // add the stream to other connections if it doesn't exist
        if (!c.outgoingStreams.find((s) => s.id === stream.id)) {
          c.outgoingStreams.push(stream);
        }
        if (!c.peer.streams.find((s) => s.id === stream.id)) {
          c.peer.addStream(stream);
        }
      });
      const audioTracks = stream.getAudioTracks();
      const videoTracks = stream.getVideoTracks();
      audioTracks.forEach((track) => {
        this.viewTrack({
          streamOwnerId,
          track,
          type: "audio",
        });
      });
      videoTracks.forEach((track) => {
        this.viewTrack({
          streamOwnerId,
          track,
          type: streamType === "webcamAndAudio" ? "webcam" : "screen",
        });
      });

      stream.onaddtrack = (e) => {
        const track = e.track;
        console.log(`
        ${streamOwnerId}\n
        added a new ${e.track.kind} track\n
        `);
        const type =
          e.track.kind === "audio"
            ? "audio"
            : streamType === "webcamAndAudio"
            ? "webcam"
            : "screen";
        // same stream for other connections
        const allConnectionsExceptStreamSender = Object.keys(this.connections)
          .filter((id) => id !== otherId)
          .map((id) => this.connections[id]);

        this.viewTrack({
          streamOwnerId,
          track,
          type,
        });

        allConnectionsExceptStreamSender.forEach((connection) => {
          const incomingStreams = connection.incomingStreams;
          const outgoingStreams = connection.outgoingStreams;
          const allConnectionsStreams = [
            ...incomingStreams,
            ...outgoingStreams,
          ];
          allConnectionsStreams.forEach((s) => {
            if (s.id === stream.id) {
              connection.peer.addTrack(track, stream);
            }
          });
        });
      };
      stream.onremovetrack = (e) => {
        console.log(`
        ${streamOwnerId}\n
        removed a ${e.track.kind} track\n
        `);
      };
    };
    const onMainConnectionTrack = async (t: MediaStreamTrack) => {
      console.log(`new ${t.kind} track from `, otherId);
    };
    const onMainConnectionEnd = () => {
      console.log(`connection Ended ${this.id} `);
    };
    const onMainConnectionClose = () => {
      console.log(`connection Closed ${this.id} `);
      this.handleConnect_Disconnect_BetweenPeers({
        name: ParticipantsCommandNames.CONNECT_DISCONNECT_EVENT_BETWEEN_NODES,
        comingFrom: this.id,
        disconnectedFrom: otherId,
        requestId,
      });
      this.sendGlobalMessage({
        payload: {
          name: "InternalChangesReport",
          reportedAboutParticipantWithId: otherId,
          reporter: this.id,
          type: "participantRemoved",
        },
      });
    };
    const onMainConnectionError = (error: Error) => {
      console.log(`connection Error ${this.id}`, error);
    };
    peer
      .on("connect", () => {
        if (connectionType === "main") {
          onMainConnectionConnected();
        }
      })
      .on("data", (d: Buffer) => {
        if (connectionType === "main") {
          this.handleIncomingMessageFromAnotherParticipant(d);
        } else if (connectionType === "shadow") {
          this.handleInconmingDataFromShadowConnection({
            ...JSON.parse(d.toString()),
            connectionId: otherId,
          } as ShadowConnectionMessage);
        }
      })
      .on("stream", (s) => {
        if (connectionType === "main") {
          onMainConnectionStream(s);
        }
      })
      .on("track", (t) => {
        if (connectionType === "main") {
          onMainConnectionTrack(t);
        }
      })
      .on("end", () => {
        if (connectionType === "main") {
          onMainConnectionEnd();
        }
      })
      .on("close", () => {
        if (connectionType === "main") {
          onMainConnectionClose();
        }
      })
      .on("error", (error) => {
        if (connectionType === "main") {
          onMainConnectionError(error);
        }
      });
  }
}
