import { useParams } from "react-router-dom";
import {
  ClientToServerCommand,
  JoinRoomRequest,
} from "../../server/node-soup/src/index.ts";
import { nanoid } from "nanoid";
import { wsAsyncRequest } from "../utils/wsAsyncRequest";
import { LittleRoom } from "../components/littleRoom.tsx";
import { appStore, storeActions } from "../store/peer.ts";
import { BottomActions } from "../components/bottomActions.tsx";
import { useState } from "react";
import { shallow } from "zustand/shallow";

export const JoinRoom = () => {
  const [joined, setJoined] = useState(false);
  const name = appStore.use.peerName();
  const peer = appStore.use.peer();
  const isJoining = appStore.use.isJoining();
  const setIsJoining = storeActions.setIsJoining;
  const isConnected = appStore.use.isPeerConnected();
  const { setMyPeerName } = appStore(
    () => ({
      addLocalPeer: storeActions.addLocalPeer,
      setMyPeerName: storeActions.setMyPeerName,
    }),
    shallow
  );
  const id = useParams().id;

  const join = async () => {
    if (isJoining || joined) return;
    if (id) {
      try {
        setIsJoining(true);
        const requestId = nanoid();
        const sendingRequest: JoinRoomRequest = {
          roomId: id,
          requestId,
          participantId: peer.id,
          myName: name,
        };
        peer.socket.emit(ClientToServerCommand.JOIN_ROOM, sendingRequest);
        await wsAsyncRequest({
          socket: peer.socket,
          requestId,
          timout: 30_000,
        });
        setIsJoining(false);
        setJoined(true);
      } catch (error) {
        setIsJoining(false);
        setJoined(false);
        console.log(error);
      }
    }
  };

  return (
    <div className="p-3 pb-28">
      <div className="flex gap-4">
        <input
          className="input"
          type="text"
          placeholder="enter your name"
          value={name}
          onChange={(e) => {
            const value = e.target.value;
            setMyPeerName(value);
          }}
        />
        <button className="btn" disabled={!name} onClick={join}>
          {isJoining ? "joining" : isConnected ? "connected" : "join"}
        </button>
      </div>
      {peer ? (
        <>
          {isConnected ? <div>my Id: {peer.id}</div> : <></>}
          <br />
        </>
      ) : (
        <> </>
      )}
      <LittleRoom />
      <BottomActions graphVisible={false} />
    </div>
  );
};
