import { useState } from "react";
import { appStore } from "../store/peer";
import { Graph } from "./graph";

export const BottomActions = ({ graphVisible }: { graphVisible?: boolean }) => {
  const [loadingScrrenShare, setLoadingScrrenShare] = useState(false);
  const [loadingAudioShare, setLoadingAudioShare] = useState(false);
  const [loadingwebcamShare, setLoadingwebcamShare] = useState(false);

  const myPeer = appStore.use.peer();
  const network = appStore.use.network();
  const numberOfParticipants = Object.keys(network).length;
  const id = myPeer.id;
  const webcamTrack = appStore.use.webcamTracks()[id];
  const screenTrack = appStore.use.screenTracks()[id];
  const audioTrack = appStore.use.audioTracks()[id];
  const isAudioEnabled = !!audioTrack?.enabled;
  const isWebcamEnabled = !!webcamTrack?.enabled;
  const isScreenEnabled = !!screenTrack?.enabled;

  const availableMics = myPeer.listOfMics;
  const availableCams = myPeer.listOfCameras;
  // const availableSpeakers = myPeer.listOfSpeakers;

  // const selectedMic = myPeer.selectedMic;
  // const selectedCam = myPeer.selectedCamera;
  // const selectedSpeaker = myPeer.selectedSpeaker;

  const handleMickClick = async () => {
    if (isAudioEnabled) {
      myPeer.stopSharingAudio();
    } else {
      setLoadingAudioShare(true);
      await myPeer.shareAudio();
      setLoadingAudioShare(false);
    }
  };
  const handleCamClick = async () => {
    if (isWebcamEnabled) {
      myPeer.stopSharingVideo("webcam");
    } else {
      setLoadingwebcamShare(true);
      await myPeer.shareVideo("webcam");
      setLoadingwebcamShare(false);
    }
  };
  const handleScreenClick = async () => {
    if (isScreenEnabled) {
      myPeer.stopSharingVideo("screen");
    } else {
      setLoadingScrrenShare(true);
      await myPeer.shareVideo("screen");
      setLoadingScrrenShare(false);
      myPeer.once("screenShareStopped", () => {
        setLoadingScrrenShare(false);
      });
    }
  };
  if (numberOfParticipants < 1) return <></>;
  return (
    <>
      <div className="flex items-center  justify-center gap-2 p-2 align-middle fixed bottom-0 w-full h-20 bg-green-300">
        <Graph showing={!!graphVisible} />

        <div className="flex justify-center align-middle gap-2">
          <button
            className="btnSmall"
            disabled={loadingAudioShare}
            onClick={handleMickClick}
          >
            {isAudioEnabled ? "stop " : "enable "}mic
          </button>
          {
            <select
              disabled={!isAudioEnabled}
              onChange={(e) => myPeer.setSelectedMic(e.target.value)}
            >
              {availableMics.map((mic) => (
                <option key={mic.deviceId} value={mic.deviceId}>
                  {mic.label.slice(0, 20)}
                </option>
              ))}
            </select>
          }
          {/* <select onChange={(e) => myPeer.setSelectedSpeaker(e.target.value)}>
            {availableSpeakers.map((speaker) => (
              <option key={speaker.deviceId} value={speaker.deviceId}>
                {speaker.label.slice(0, 20)}
              </option>
            ))}
          </select> */}
        </div>
        <div className="flex justify-center align-middle gap-2">
          <button
            className="btnSmall"
            disabled={loadingwebcamShare}
            onClick={handleCamClick}
          >
            {isWebcamEnabled ? "stop " : "enable "}webcam
          </button>
          <select
            disabled={!isWebcamEnabled}
            onChange={(e) => myPeer.setSelectedCamera(e.target.value)}
          >
            {availableCams.map((cam) => (
              <option key={cam.deviceId} value={cam.deviceId}>
                {cam.label.slice(0, 20)}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button
            className="btnSmall"
            disabled={loadingScrrenShare}
            onClick={handleScreenClick}
          >
            {isScreenEnabled ? "stop " : "start sharing"} screen
          </button>
        </div>
      </div>
    </>
  );
};
