import SimplePeer from "../ting-simple-peer";
// import SimplePeer from "simple-peer";
import { iceServers } from "./rtcConfigs";

export const newPeer = ({
  initiator,
  wrtc,
}: {
  initiator: boolean;
  wrtc?: any;
}) => {
  return new SimplePeer({
    initiator,
    trickle: false,
    ...(iceServers.length > 0 ? { config: { iceServers } } : {}),
    ...(wrtc ? { wrtc } : {}),
  });
};
export type SignalData = SimplePeer.SignalData;
export type PeerInstance = SimplePeer.Instance;
