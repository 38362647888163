import { NetworkConfigUpdate } from "../../server/node-soup/src/index.ts";
import { AppState, ParticipantTracks } from "../store/peer.ts";

export interface Node extends d3.SimulationNodeDatum {
  id: string;
  name: string;
  tracks: ParticipantTracks;
}

export interface Link extends d3.SimulationLinkDatum<Node> {
  source: string;
  target: string;
  value: number;
}

export type Data = {
  nodes: Node[];
  links: Link[];
};

export const dataParser = (
  data: AppState["networkWithoutScores"],
  tracks: {
    screen: AppState["screenTracks"];
    audio: AppState["audioTracks"];
    webcam: AppState["webcamTracks"];
  }
) => {
  const d: Data = { nodes: [], links: [] };
  // this function parses the data like above
  Object.keys(data).forEach((key) => {
    d.nodes.push({
      id: key,
      name: data[key].name,
      tracks: {
        audio: tracks.audio[key],
        screen: tracks.screen[key],
        video: tracks.webcam[key],
      },
    });

    data[key].connections.forEach((node) => {
      d.links.push({ source: key, target: node, value: 1 });
    });
  });
  return d;
};
