import { nanoid } from "nanoid";
import io from "socket.io-client";
import { debug } from "./loger";
import { baseUrl } from "../consts";
const getSessionId = () => {
  const id = sessionStorage.getItem("ID") || nanoid();
  sessionStorage.setItem("ID", id);
  return id;
};
const basePath = import.meta.env.VITE_BASE_PATH;
let path = "/socket.io";
if (!baseUrl.includes("localhost")) {
  path = `/${basePath}/socket.io`;
}
export const createSocket = () => {
  const socket = io(baseUrl, {
    withCredentials: false,
    path,
  });

  return socket;
};
